import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import './shared/helpers.css';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import Preloader from "./shared/Preloader";
import { SnackbarProvider } from 'notistack';

const root = createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <SnackbarProvider preventDuplicate classes={{
    // variantSuccess: 'success-snack'
  }} anchorOrigin={{horizontal: 'center', vertical: 'bottom'}} autoHideDuration={6000} maxSnack={3}>
    <Suspense fallback={<Preloader />}>
      <App />
    </Suspense>
  </SnackbarProvider>
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

