// @flow
import * as React from 'react';
import {Box, Drawer, Card, IconButton, Typography} from "@mui/material";
import {AgentCallAgendaTaskHistoryItemOutput} from "../../../enrollment-types";
import {useContext, useState} from "react";
import DataGrid, {DataGridColumn} from "../../../shared/DataGrid";

type Props = {
  open: boolean,
  onClose: () => void,
  rows: AgentCallAgendaTaskHistoryItemOutput[]
};

function RoundRobinTaskHistoryDrawer({open, onClose, rows}: Props) {
  return (
    <Drawer
      anchor={'right'}
      open={open}
      onClose={onClose}
    >
      <Card sx={{p: 4, width: 594, overflowY: 'auto'}}>
        <Box sx={{display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between', mb: 3}}>
          <Typography variant={'h3'}>Assignment History</Typography>
          <IconButton size={'small'} onClick={onClose}>
            <img className={'w-32 h-32'} src={'/img/X.svg'}/>
          </IconButton>
        </Box>

        <DataGrid columns={columns}
                  page={0}
                  hidePaginator
                  rowsPerPage={1000}
                  rows={rows}/>

      </Card>
    </Drawer>
  );
}

const columns: DataGridColumn<AgentCallAgendaTaskHistoryItemOutput, keyof AgentCallAgendaTaskHistoryItemOutput>[] =  [
  {
    title: 'Type',
    value: v => v.type,
  },
  {
    title: 'Agent',
    value: v => v.agentEmail || 'unknown',
  },
]



const RoundRobinTaskHistoryDrawerContext = React.createContext<{
  open: (rows: AgentCallAgendaTaskHistoryItemOutput[]) => void;
}>({
  open: () => {},
})

export function withRoundRobinTaskHistoryDrawer<P>(WrappedComponent: React.ComponentType<P>) {
  return (props: P) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [rows, setRows] = useState<AgentCallAgendaTaskHistoryItemOutput[]>([])

    const open = (r: AgentCallAgendaTaskHistoryItemOutput[]) => {
      setRows(r);
      setDrawerOpen(true);
    }

    const handleClose = () => {
      setDrawerOpen(false)
      setRows([]);
    }

    return <RoundRobinTaskHistoryDrawerContext.Provider value={{open}}>
      <RoundRobinTaskHistoryDrawer open={drawerOpen}
                                   rows={rows}
                                   onClose={handleClose} />
      <WrappedComponent {...props} />
    </RoundRobinTaskHistoryDrawerContext.Provider>
  }
}

export const useRoundRobinTaskHistoryDrawer = () => {
  return useContext(RoundRobinTaskHistoryDrawerContext);
}