import Card from "../shared/Card";
import React, {useState} from "react";
import PersonalInfoForm, {PersonalInfoFormValues} from "./components/PersonalInfoForm";
import {GraphQLErrorType} from "../Apollo/EnrollmentApolloClient";
import {useSnackbar} from "notistack";
import {
  ProfileOption,
  StatusDto,
  useMergeClientsInOneGroupMutation, useSaveStatusMutation,
  useSaveUserProfileMutation
} from "../enrollment-types";
import {Box} from "@mui/material";
import {useHistory} from "react-router";
import paths from "../router-paths";
import moment from "moment";
import {useLocation} from "react-router-dom";
import { getUserId } from "../shared/keycloak";

const CreateClientForm = () => {
  const {enqueueSnackbar} = useSnackbar();
  let history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search)

  const [loading, setLoading] = useState(false)

  const [createGroup] = useMergeClientsInOneGroupMutation();
  const [saveStatus] = useSaveStatusMutation();
  const [create] = useSaveUserProfileMutation({
    onError: (error) => {
      let text;
      switch (error.graphQLErrors[0]?.extensions?.type) {
        case GraphQLErrorType.ALREADY_EXISTS:
          text = 'Looks like that email is already in use.';
          break;
        case GraphQLErrorType.NO_CONNECTION:
          text = "Service is not available";
          break;
        default:
          text = "Unknown error"
      }
      enqueueSnackbar(text, {variant: "error"});
    },
  })

  const onSubmit = async (values: PersonalInfoFormValues) => {
    setLoading(true);

    try {
      const res = await create({
        variables: {
          data: {
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            birthDate: moment(values.birthDate).format('YYYY-MM-DD'),
            gender: values.gender,
            zip: values.zip,
            countyName: values.county,
            state: values.state,
            phoneNumber: values.phoneNumber,
            options: values.email ? [ProfileOption.RegisterUser] : undefined,
          }
        }
      });

      if (params.has('status')) {
        await saveStatus({
          variables: {
            agentId: getUserId(),
            id: res.data?.saveUserProfile.clientId,
            status: params.get('status') as StatusDto,
            followUpDate: params.get('followUpDate') || undefined,
          }
        })
      }

      if (params.has('spouse')) {
        await createGroup({
          variables: {
            clientIds: [params.get('spouse')!, res.data?.saveUserProfile.clientId]
          }
        })
      }

      history.push(paths.client + '/' + res.data?.saveUserProfile.clientId)
      enqueueSnackbar('User saved!', {variant: "success"});
    } catch (error: any) {
      let text;
      switch (error.graphQLErrors[0]?.extensions?.type) {
        case GraphQLErrorType.ALREADY_EXISTS:
          text = 'Looks like that email is already in use.';
          break;
        case GraphQLErrorType.NO_CONNECTION:
          text = "Service is not available";
          break;
        default:
          text = "Unknown error"
      }
      enqueueSnackbar(text, {variant: "error"});
    } finally {
      setLoading(false);
    }
  };

  return <Box maxWidth={600}>
    <Card>
      <PersonalInfoForm onSubmit={onSubmit}
                        gender={params.get('gender') || undefined}
                        zip={params.get('zip') || undefined}
                        county={params.get('countyName') || undefined}
                        state={params.get('state') || undefined}
                        initialBirthDate={params.get('birthDate') || undefined}
                        initialFirstName={params.get('firstName') || undefined}
                        initialLastName={params.get('lastName') || undefined}
                        initialPhoneNumber={params.get('phoneNumber') || undefined}
                        onCancel={() => history.push(paths.clients)}
                        loading={loading}
                        focusField={'email'}
      />
    </Card>
  </Box>
}

export default CreateClientForm;
