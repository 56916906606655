import {DoctorAddressViewOutput} from "../../../../../../../enrollment-types";
import {gql, useQuery} from "@apollo/client";
import {client} from "../../../../../../../Apollo/ApolloClient";

export const useAddresses = (npi?: string, addresses?: DoctorAddressViewOutput[]) => {
  const {data} = useQuery<{findProviderAddressesByNpi: DoctorAddressViewOutput[]}, {npi: string}>(gql(getAddressesQuery), {
    skip: !npi || !!addresses?.length,
    fetchPolicy: 'no-cache',
    client,
    variables: { npi: npi! }
  });

  return addresses ? addresses : data?.findProviderAddressesByNpi || []
}

const getAddressesQuery = `
query ($npi: String!) {
  findProviderAddressesByNpi(npi: $npi) {
    addressLine1
    addressLine2
    id
    specialty
  }
}
`