import React, {useEffect} from "react";
import {Box, Button, Tab, Typography} from "@mui/material";
import PersonalInfo from "../../Client/widgets/PersonalInfo";
import UserPriorities from "./ui/UserPriorities";
import {RouteComponentProps, useParams} from "react-router-dom";
import {
  ClientViewOutput,
  useClientCardSubscription,
  useStartTestCallFor30SecondsMutation
} from "../../enrollment-types";
import Notes from "../../Client/widgets/notes/Notes";
import Status from "./ui/status/Status";
import CallHistory from "./ui/call-history/CallHistory";
import MedigapQuote from "./ui/MedigapQuote";
import ClientTags from "../../Client/widgets/ClientTags";
import PdpQuote from "./ui/PdpQuote";
import ClientFiles from "../../Client/widgets/ClientFiles";
import {keycloak, KeycloakRoles} from "../../shared/keycloak";
import {YearToYearCompare} from "../../Client/widgets/YearToYearCompare";
import ClientGroups from "./ui/client-groups/ClientGroups";
import SetUserIdForm from "../../Client/components/SetUserIdForm";
import Card from "../../shared/Card";
import Doctors from "./ui/doctors/Doctors";
import Drugs from "./ui/Drugs";
import QuizAnswers from "../../Client/widgets/QuizAnswers";
import ChannelAttribution from "../../Client/widgets/ChannelAttribution";
import ClientEmails from "../../Client/widgets/emails/ClientEmails";
import useClientCommunicationNoShow from "../../Client/hooks/useClientCommunicationNoShow";
import CustomerIoLinkButton from "./ui/CustomerIoLinkButton";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import {CloseClientPrompt} from "../../Client/components/CloseClientPrompt";
import {withDropZone} from "./ui/withDropZone";
import {styled} from "@mui/styles";
import LeadSources from "./ui/LeadSources";
import LoginStats from "./ui/LoginStats";
import EmailStats from "./ui/EmailStats";
import MedicareQuote from "./ui/medicare-quote";
import {ClientEmailTemplates} from "./ui/email-templates/ClientEmailTemplates";
import {CompareOffer} from "./ui/compare-offer";
import {ClientEnrollments} from "./enrollment";
import {CalendlyAppointments} from "./ui/CalendlyAppointments";
import {ClientDisclamer} from "./ui/ClientDisclamer";

const ClientDetails = (props: RouteComponentProps<{ id: string }>) => {
  const params = useParams<{id: string, tab?: string, agentId?: string, emailId?: string}>();
  const {id} = props.match.params;
  const [tab, setTab] = React.useState(params.tab === 'enroll' ? '2' : params.tab === 'email' ? '3' : '1');

  const [noShow] = useClientCommunicationNoShow()
  const {data, loading} = useClientCardSubscription({ variables: { id } });

  const isTech = keycloak?.realmAccess?.roles?.includes(KeycloakRoles.ROLE_TECH_ADMIN)
  const isAdmin = keycloak?.realmAccess?.roles?.includes(KeycloakRoles.ROLE_ADMIN)

  useEffect(() => {
    return () => {
      sessionStorage.removeItem('userConfirmationModalShowed')
    }
  }, []);

  const changeTab = (newVal: string) => {
    setTab(newVal)
  }

  const client = data?.clientCard as ClientViewOutput | undefined;

  const county = React.useMemo(() => {
      if (!client?.countyName && client?.customAnswers) {
        const parsed = JSON.parse(client.customAnswers);
        if (parsed?.STEP_2?.county || parsed?.STEP_2?.countyName) {
          return parsed?.STEP_2?.county || parsed?.STEP_2?.countyName;
        }
      }
      return client?.countyName;
  }, [client]);

  return <div>
    {!!client && <CloseClientPrompt client={client} />}
    <TabContext value={tab}>
      <TabList onChange={(e, val) => changeTab(val)}>
        <Tab label={`Info`} value="1" />
        <Tab label={`Enrollments`} value="2" />
        <Tab label={`Emails`} value="3" />
        <Tab label={`Email templates`} value="4" />
      </TabList>
      <Box sx={{ borderBottom: 8, borderColor: 'divider', mt: -1 }} />

      <TabPanel value="1">
        <Wrapper>
          <Column sx={{flex: 1}}>
            {isTech && <Card>
              <SetUserIdForm client={client} />
            </Card>}
            <PersonalInfo client={client} />
            <ChannelAttribution client={client} />
            <ClientGroups client={client}/>
            {/*<ProductCounter client={client}/>*/}
            {/*
            {maEnrollments.map(enrollment => (
              <EnrollmentInfo enrollment={enrollment} viewOnly />
            ))}
            {mgEnrollments.map(enrollment => (
              <MedigapEnrollmentInfo enrollment={enrollment} viewOnly />
            ))}
            {pdpEnrollments.map(enrollment => (
              <PdpEnrollmentInfo enrollment={enrollment} viewOnly />
            ))}*/}
          </Column>


          <Column sx={{flex: 2, maxWidth: 'calc(50%)'}}>
            <UserPriorities client={client} />
            <Doctors client={client} />
            <Drugs client={client} />

            <Box sx={{display: 'flex', gap: 2, alignItems: 'flex-start'}}>
              <MedicareQuote pin={client?.pin || undefined} client={client} county={county} />
              <MedigapQuote client={client} />
              <PdpQuote client={client} />
            </Box>

            <QuizAnswers client={client} county={county} answers={client?.customAnswers ? JSON.parse(client.customAnswers) : undefined} />
            {isAdmin && <CallHistory client={client} phoneNumber={client?.phoneNumber} loading={loading} />}
            <LeadSources clientId={client?.id} loading={loading} />
            <EmailStats email={client?.email} loading={loading} />
            <LoginStats email={client?.email} loading={loading} />
          </Column>


          <Column sx={{flex: 1}}>
            <Status client={client}/>
            <Notes client={client}/>
            <ClientTags client={client}/>
            <YearToYearCompare client={client}/>
            <CompareOffer client={client}/>
            <ClientFiles client={client}/>
            <div>
              {!client?.noShow && <Button variant={'contained'} color={'secondary'} onClick={() => noShow({variables: {clientId: client?.id}})}>No show</Button>}
              {!!client?.noShow && <Typography color={"textPrimary"} variant={"body1"} className={"medium"}>Client didn't show up</Typography>}
            </div>
            {client?.id && <CustomerIoLinkButton clientId={client?.id}/>}
            <CalendlyAppointments client={client} />
          </Column>

        </Wrapper>
      </TabPanel>

      <TabPanel value="2">
        <ClientEnrollments client={client} />
      </TabPanel>

      <TabPanel value="3">
        <ClientEmails client={client} agentId={params.agentId} emailId={params.emailId}  />
      </TabPanel>

      <TabPanel value="4">
        <ClientEmailTemplates />
      </TabPanel>

    </TabContext>

    <ClientDisclamer zip={client?.zip} />
  </div>;
};

export default withDropZone(ClientDetails);


const Wrapper = styled(Box)({
  display: 'flex',
  gap: 16
})

const Column = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 16
})