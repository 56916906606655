import * as React from 'react';
import * as _ from 'lodash';
import {CalcDrugFrequencyPeriod, FrequencyPeriod, PackInfoOuput} from "../../../types";
import {
  Box,
  Button, Chip,
  Collapse,
  Dialog,
  IconButton,
  MenuItem, SelectChangeEvent,
  Typography
} from "@mui/material";
import {makeStyles, createStyles} from "@mui/styles";
import TextInput from "../../TextInput";
import {useGetPackagesDescription} from "../hooks/use-get-packages-description";
import {PreferredDrugViewOutput, TagViewOutput} from "../../../enrollment-types";
import {DrugName} from "../DrugSelection";
import FormSelect from "../../FormSelect";


const useStyles = makeStyles(() =>
  createStyles({
    title: {
      textAlign: 'center',
      lineHeight: '36px',
    },
    delimeter: {
      margin: '10px 20px 0',
      textAlign: 'center',
    },
    icon: {
      position: 'absolute!important' as any,
      top: 8,
      right: 8,
    },
    menuItem: {
      whiteSpace: 'unset'
    }
  })
);

export interface DrugDosage {
  dosage: string,
  quantity?: number,
  frequency: string,
  purchaseFrequency: FrequencyPeriod,
}

interface DrugDosageModalModalProps {
  open: boolean,
  drug?: DrugName,
  dosage?: PreferredDrugViewOutput,
  packages?: PackInfoOuput[],
  onClose: (result?: DrugDosage | boolean) => void,
}

const initialDosage: DrugDosage = {
  dosage: '',
  quantity: undefined,
  frequency: 'MONTHLY',
  purchaseFrequency: 'MONTHLY' as FrequencyPeriod
};

export function DrugDosageModal(props: DrugDosageModalModalProps) {
  const classes = useStyles();
  const [dosage, setDosage] = React.useState<DrugDosage>(initialDosage);
  const [getDescription, {data}] = useGetPackagesDescription();
  const [description, setDescription] = React.useState<string>();
  const [unitLabel, setUnitLabel] = React.useState<string>('-');

  React.useEffect(() => {
    if (props.open) {
      setDosage(props.dosage ? {
        frequency: props.dosage?.frequency as unknown as CalcDrugFrequencyPeriod || CalcDrugFrequencyPeriod.Daily,
        quantity: props.dosage?.quantity,
        dosage: props.dosage?.dosageRxcui || '',
        purchaseFrequency: props.dosage?.purchaseFrequency as unknown as FrequencyPeriod || FrequencyPeriod.Monthly
      } : initialDosage);
      if (props.dosage?.dosageRxcui) {
        getDescription({variables: {rxcui: props.dosage.dosageRxcui}})
      }
    } else {
      setDescription(undefined)
      setUnitLabel('-')
    }
  }, [props.open])

  React.useEffect(() => {
    setDescription(data?.packageDescriptionByRxcui?.packageDescription || '')
    setUnitLabel(data?.packageDescriptionByRxcui?.unitType || '-')
  }, [data]);

  const frequencies = [CalcDrugFrequencyPeriod.Daily, CalcDrugFrequencyPeriod.Weekly, CalcDrugFrequencyPeriod.Monthly, CalcDrugFrequencyPeriod.Quarterly, CalcDrugFrequencyPeriod.HalfYearly, CalcDrugFrequencyPeriod.Yearly];

  const handleFrequencyChanged = (event: SelectChangeEvent<unknown>) => {
    const frequency = event.target.value as string;
    let purchaseFrequency: FrequencyPeriod;

    switch (frequency) {
      case CalcDrugFrequencyPeriod.Quarterly: purchaseFrequency = FrequencyPeriod.Quarterly; break;
      case CalcDrugFrequencyPeriod.HalfYearly: purchaseFrequency = FrequencyPeriod.HalfYearly; break;
      case CalcDrugFrequencyPeriod.Yearly: purchaseFrequency = FrequencyPeriod.Yearly; break;
      default: purchaseFrequency = dosage.purchaseFrequency;
    }

    setDosage({
      ...dosage,
      frequency,
      purchaseFrequency,
    });
  }

  return <Dialog
    open={props.open}
    onClose={() => props.onClose()}
    PaperProps={{
      className: 'p-30'
    }}
  >
    <>
      <IconButton onClick={() => props.onClose()} className={classes.icon}><img src={'/img/icon-close.svg'} /></IconButton>
      <Typography className={'fs-25 lh-31 bold mt-10'} align={'center'}>{props.drug?.name}</Typography>
      <Typography className={'fs-18 lh-22 mt-8'} align={'center'}>Enter dosage and frequency</Typography>
      <Box mt={'24px'}>
        <FormSelect label={'What dosage do you take?'}
                    value={dosage?.dosage}
                    onChange={event => {
                      setDosage({
                        ...dosage,
                        dosage: event.target.value as string,
                      });
                      getDescription({variables: {rxcui: event.target.value as string}})
                    }}
        >
          {props.packages?.map(p => <MenuItem data-test={'dosage_select_item_' + p.rxcui} key={p.rxcui} value={p.rxcui as string} className={classes.menuItem}>{p.name}</MenuItem>)}
        </FormSelect>
        <Collapse in={!!description}>
          <Typography className={'fs-16 medium'} color={'textPrimary'}>Package contains:</Typography>
          <Typography className={'fs-14 ml-16 mt-8 mb-16'} color={'textSecondary'}>{description}</Typography>
        </Collapse>
      </Box>
      <Box sx={{display: 'flex', alignItems: 'flex-start', flexDirection: {xs: 'column', md: 'row'}}}>
        <TextInput type={'text'}
                   label={unitLabel}
                   placeholder={'Enter quantity'}
                   className={'mr-10'}
                   value={dosage?.quantity}
                   inputProps={{
                     onKeyPress: (event: any) => (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57
                   }}
                   onChange={event => {
                     let quantity: number | undefined = parseFloat(event.target.value as string);
                     if (isNaN(quantity)) {
                       quantity = undefined
                     }
                     setDosage({
                       ...dosage,
                       quantity,
                     });
                   }}
        />
        <FormSelect label={'Frequency'}
                    placeholder={'Please select'}
                    value={dosage?.frequency || 'month'}
                    className={'ml-10'}
                    onChange={handleFrequencyChanged}
        >
          {frequencies.map(f => <MenuItem key={f} value={f}>{_.upperFirst(_.lowerCase(f))}</MenuItem>)}
        </FormSelect>
      </Box>
      <Box display={'flex'} alignItems={'flex-start'}>
        <FormSelect label={'Purchase frequency'}
                     placeholder={'Please select'}
                     value={dosage?.purchaseFrequency || FrequencyPeriod.Monthly}
                     onChange={event => {
                       setDosage({
                         ...dosage,
                         purchaseFrequency: event.target.value as FrequencyPeriod,
                       });
                     }}
        >
          <MenuItem
              value={FrequencyPeriod.Monthly}
              className={'regular'}
              disabled={
                  dosage?.frequency === CalcDrugFrequencyPeriod.Quarterly ||
                  dosage?.frequency === CalcDrugFrequencyPeriod.HalfYearly ||
                  dosage?.frequency === CalcDrugFrequencyPeriod.Yearly
              }
          >
            Monthly
          </MenuItem>

          <MenuItem value={FrequencyPeriod.Quarterly}
                    disabled={
                        dosage?.frequency === CalcDrugFrequencyPeriod.HalfYearly || dosage?.frequency === CalcDrugFrequencyPeriod.Yearly
                    }
                    className={'regular'}>
            Quarterly
          </MenuItem>

          <MenuItem value={FrequencyPeriod.HalfYearly}
                    disabled={
                        dosage?.frequency === CalcDrugFrequencyPeriod.Yearly
                    }
                    className={'regular'}>
            Half Yearly
          </MenuItem>

          <MenuItem value={FrequencyPeriod.Yearly} className={'regular'}>
            Yearly
          </MenuItem>
        </FormSelect>
      </Box>

      <Box sx={{
        display: 'flex',
        width: '100%',
        mt: '28px',
        justifyContent: 'space-between',
        alignItems: {xs: 'center', md: 'flex-start'},
        flexDirection: {xs: 'column-reverse', md: 'row'}
      }}>
        <Button variant={'outlined'} onClick={() => {props.onClose(false)}} className={'regular w-175 mr-10'}>Cancel</Button>
        <Button data-test={'add_drug_button'} color={'primary'} variant={'contained'} onClick={() => props.onClose(dosage)} className={'w-175 ml-10'} disabled={!dosage.dosage || !dosage.quantity}>Save</Button>
      </Box>
    </>
  </Dialog>
}
