import {Box, Chip, SvgIcon} from '@mui/material';
import * as React from 'react';
import * as _ from 'lodash';
import {ClientViewFilterInput, DateRangeInput} from "../../../enrollment-types";
import {labelByStatus} from "../../../features";
import { getAgentById } from "../../../Agent/hooks/useAgentById";
import {getAgentDisplayName} from "../../../Agent/AgentSelector";
import {useEffect, useState} from "react";
import moment from "moment/moment";

interface ClientListFilterBadgesProps {
  filter?: { [key: string]: any },
  onRemoveFilterClick: (filterName: keyof ClientViewFilterInput) => void
}

export default function ClientListFilterBadges({filter, onRemoveFilterClick}: ClientListFilterBadgesProps) {
  const [badges, setBadges] = useState<any[]>([]);

  useEffect(() => {
    (async () => {
      const result = [];
      for (const filterKey in filter) {
        if (filter[filterKey]) {
          result.push(
            getFilter(
              await mapFilterLabel(filterKey, filter[filterKey]),
              () => onRemoveFilterClick(filterKey as keyof ClientViewFilterInput)
            )
          )
        }
      }
      setBadges(result);
    })()
  }, [filter]);

  return <Box sx={{flexWrap: 'wrap', display: 'flex', gap: 1, px: 2, pb: 1}}>
    {badges}
  </Box>
}

const getFilter = (title: string, onDelete: () => void) =>
  <Chip sx={{borderRadius: '3px', fontSize: 14, height: 28, backgroundColor: '#2C697C4D'}}
        key={title}
        label={title}
        onDelete={onDelete}
        deleteIcon={<SvgIcon>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#1C434F" viewBox="0 0 256 256" className="MuiChip-deleteIcon MuiChip-deleteIconMedium MuiChip-deleteIconColorDefault MuiChip-deleteIconFilledColorDefault"><path d="M216,40H68.53a16.12,16.12,0,0,0-13.72,7.77L9.14,123.88a8,8,0,0,0,0,8.24l45.67,76.11h0A16.11,16.11,0,0,0,68.53,216H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40ZM165.66,146.34a8,8,0,0,1-11.32,11.32L136,139.31l-18.35,18.35a8,8,0,0,1-11.31-11.32L124.69,128l-18.35-18.34a8,8,0,1,1,11.31-11.32L136,116.69l18.34-18.35a8,8,0,0,1,11.32,11.32L147.31,128Z"></path></svg>
        </SvgIcon>} />

const mapFilterLabel = async (name: string, value: any) => {

  const filterName = _.upperFirst(_.lowerCase(_.kebabCase(name)))
  switch (name as keyof ClientViewFilterInput) {
    case "status": return `${filterName}: ${labelByStatus(value)}`;
    case "range":
    case "birthDate": {
      return `${filterName}: ${dateRangeStr(value)}`;
    }
    case "agentId": {
      const agent = await getAgentById(value).then(res => res.data?.agentById)
      return `Agent: ${agent ? getAgentDisplayName(agent) : 'unknown'}`
    }
    default: return `${filterName}: ${value}`;
  }
}

const dateRangeStr = (dateRange: DateRangeInput) => {
  if (!dateRange.from) {
    return 'Not selected';
  }
  return moment(dateRange.from).format('L') + ' - ' + moment(dateRange.to).format('L')
}
