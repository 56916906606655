import React, {useState} from "react";
import * as yup from 'yup';
import {useFormik} from "formik";
import TextInput from "../../shared/TextInput";
import {Box, Button, Grid, InputAdornment, MenuItem} from "@mui/material";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import moment from "moment";
import {config} from "../../config/config";
import FormSelect from "../../shared/FormSelect";
import MaskedInput from 'react-text-mask'
import {InferType} from "yup";
import useCheckUserExistenceByEmail from "../hooks/useCheckUserExistenceByEmail";
import {ZipSelector} from "../../features";

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Enter a valid email'),
  firstName: yup
    .string()
    .required('First name is required'),
  lastName: yup
    .string()
    .required('Last name is required'),
  gender: yup
    .string()
    .required('Gender is required'),
  phoneNumber: yup
    .string()
    .required('Phone is required')
    .test('valid', 'Phone format is not valid', value => {
      return /^(\d{10})/gm.test(value?.replace(/\D/g, ""))
    })
  ,
  birthDate: yup
    .date().nullable()
    .typeError('Please enter a valid date')
    .required('Birthdate is required'),
  zip: yup
    .string()
    .required('Zip is required'),
  county: yup.string().required('County is required'),
  state: yup.string(),
});

export interface PersonalInfoFormValues {
  email: string;
  birthDate: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  gender: string;
  zip: string;
  county: string,
  state: string,
}

interface PersonalInfoFormProps {
  initialEmail?: string;
  initialFirstName?: string;
  initialLastName?: string;
  initialBirthDate?: string;
  initialPhoneNumber?: string;
  gender?: string;
  zip?: string;
  county?: string;
  state?: string;
  onSubmit: (values: PersonalInfoFormValues) => Promise<any>,
  onCancel: () => void,
  loading: boolean,
  focusField?: string
}

const getInitialValues = (props: PersonalInfoFormProps): InferType<typeof validationSchema> => ({
  email: props.initialEmail || '',
  birthDate: props.initialBirthDate ? moment(props.initialBirthDate).toDate() : null,
  firstName: props.initialFirstName || '',
  lastName: props.initialLastName || '',
  phoneNumber: props.initialPhoneNumber || '',
  gender: props.gender || '',
  zip: props.zip || '',
  county: props.county || '',
  state: props.state || '',
})

const PersonalInfoForm = (props: PersonalInfoFormProps) => {

  const formik = useFormik({
    initialValues: getInitialValues(props),
    validationSchema: validationSchema,
    onSubmit: values => {
      props.onSubmit({
        ...values,
        birthDate: moment(values.birthDate).format(config.dateFormat),
        phoneNumber: '+1' + values.phoneNumber.replace(/\D/g, "")
      });
    },
  });

  const [checkEmail] = useCheckUserExistenceByEmail();

  React.useEffect(() => {
    if (props.initialEmail && props.initialEmail !== formik.values.email && yup.string().email().isValidSync(formik.values.email)) {
      checkEmail({variables: {email: formik.values.email}})
        .then(res => res.data?.checkUserExistenceByEmail && formik.setFieldError('email', 'This email is assigned to other client'))
    }
  }, [formik.values.email, props.initialEmail]);

  return (
    <form onSubmit={formik.handleSubmit} className={'mt-15'}>
      <Grid container spacing={1} direction={'row'}>
        <Grid item xs={6}>
          <TextInput
            fullWidth
            autoFocus={props.focusField === 'firstName'}
            name="firstName"
            label="First Name"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            fullWidth
            autoFocus={props.focusField === 'lastName'}
            name="lastName"
            label="Last Name"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
          />
        </Grid>
      </Grid>
      <FormSelect label={'Gender'}
                  autoFocus={props.focusField === 'gender'}
                  value={formik.values.gender}
                  onChange={event => {
                    formik.setFieldValue("gender", event.target.value as string);
                  }}
                  error={formik.touched.gender && Boolean(formik.errors.gender)}
                  helperText={formik.touched.gender ? formik.errors.gender : undefined}
      >
        <MenuItem value={'M'}>Male</MenuItem>
        <MenuItem value={'F'}>Female</MenuItem>
      </FormSelect>
      <TextInput
        name="phoneNumber"
        autoFocus={props.focusField === 'phoneNumber'}
        placeholder={'11234567890'}
        label="Phone"
        InputProps={{
          startAdornment: <InputAdornment position="start">+1</InputAdornment>,
          inputComponent: MaskedInput as any,
          inputProps: {
            mask: ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/] as any
          }
        }}
        value={formik.values.phoneNumber}
        onChange={formik.handleChange}
        error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
        helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
      />
      <TextInput
        fullWidth
        autoFocus={props.focusField === 'email'}
        name="email"
        label="Email"
        value={formik.values.email}
        onChange={formik.handleChange}
        error={Boolean(formik.errors.email)}
        helperText={formik.errors.email}
      />

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          disableFuture
          autoFocus={props.focusField === 'birthDate'}
          slotProps={{
            textField: {
              error: formik.touched.birthDate && Boolean(formik.errors.birthDate),
              helperText: formik.touched.birthDate && formik.errors.birthDate,
              inputProps: {
                placeholder: 'MM / DD / YYYY'
              }
            }
          }}
          slots={{
            textField: TextInput as any,
          }}
          format="MM/dd/yyyy"
          label={'Birthdate'}
          value={moment(formik.values.birthDate).toDate()}
          onChange={value => formik.setFieldValue("birthDate", value)}
        />
      </LocalizationProvider>

      <ZipSelector zip={formik.values.zip}
                   county={formik.values.county}
                   zipError={formik.touched.zip ? formik.errors.zip : undefined}
                   countyError={formik.touched.county ? formik.errors.county : undefined}
                   onChange={data => {
                     formik.setFieldValue('zip', data.zip);
                     formik.setFieldValue('county', data.county);
                     formik.setFieldValue("state", data.state);
                   }} />

      <Box sx={{display: 'flex', gap: 2}}>
        <Button variant={'outlined'} onClick={props.onCancel}>CANCEL</Button>
        <Button disabled={props.loading} variant={'contained'} color={'primary'} type={'submit'}>SAVE</Button>
      </Box>
    </form>
  );
};

export default PersonalInfoForm;
