import {DateRangeInput} from "../../enrollment-types";
import React, {useEffect, useState} from "react";
import {Box} from "@mui/material";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import TextInput from "../TextInput";
import moment from "moment";

interface FilterProps {
  value?: DateRangeInput | null,
  onChange: (value?: DateRangeInput) => void,
  label: string
}

const DaterangeFilter = ({value, onChange, label}: FilterProps) => {

  return <Box display={'flex'} alignItems={'center'} gap={1}>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        disableFuture
        slotProps={{
          textField: {
            inputProps: {
              placeholder: 'MM / DD / YYYY',
              className: 'mb-0'
            },
          }
        }}
        className={'mb-0'}
        slots={{
          textField: TextInput as any,
        }}
        format="MM/dd/yyyy"
        label={label + ' from'}
        value={value?.from ? moment(value!.from).toDate() : null}
        onChange={date => onChange({
          from: date ? moment(date).startOf('day').toISOString() : date,
          to: value?.to || moment().startOf('day').toISOString()
        })}
      />
    </LocalizationProvider>

    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        slotProps={{
          textField: {
            inputProps: {
              placeholder: 'MM / DD / YYYY',
              className: 'mb-0'
            },
          }
        }}
        className={'mb-0'}
        slots={{
          textField: TextInput as any,
        }}
        format="MM/dd/yyyy"
        label={label + ' to'}
        value={value?.to ? moment(value!.to).toDate() : null}
        onChange={date => onChange({
          from: value?.from || moment(date).startOf('day').toISOString(),
          to: date ? moment(date).startOf('day').toISOString() : date
        })}
      />
    </LocalizationProvider>
  </Box>
}

export default DaterangeFilter;
