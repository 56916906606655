import {Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal } from "@mui/material";
import React, {FunctionComponent, useState} from "react";


interface ConfirmDialogContextData {
  content: string | JSX.Element | FunctionComponent<ConfirmModalFunctionComponentProps>,
  okButtonTitle?: string,
  okButtonColor?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
  cancelButtonTitle?: string,
  validate?: (value: any) => Promise<boolean>
  title?: string,
  width?: string | number,
}

export const ConfirmDialogContext = React.createContext((data: ConfirmDialogContextData) => new Promise(resolve => {}))

export function ConfirmDialogProvider({ children }: { children: any }) {
  const ref = React.useRef();
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState<ConfirmDialogContextData>();

  const confirm = (data: ConfirmDialogContextData) => {
    setState(data)
    setOpen(true);
    return new Promise<any>((resolve) => {
      (ref as any).current = resolve;
    })
  }

  const fn = (choice: any) => {
    (ref as any).current(choice)
    ref.current = undefined;
  }

  return (
    <ConfirmDialogContext.Provider value={confirm}>
      {children}
      {state?.content && <ModalWrapper
        {...state}
        open={open}
        onClose={(value?: any) => {
          if (state.validate && value) {
            state.validate(value).then((res) => {
              if (res) {
                setOpen(false);
                fn(value);
              }
            })
          } else {
            setOpen(false);
            fn(value);
          }
        }}
      />}
    </ConfirmDialogContext.Provider>
  )
}

export const withConfirmDialog = (WrappedComponent: any) => (props: any) => {
  return (
    <ConfirmDialogProvider>
      <WrappedComponent {...props} />
    </ConfirmDialogProvider>
  )
}

export function useConfirm() {
  return React.useContext(ConfirmDialogContext)
}

export interface ConfirmModalFunctionComponentProps {
  onValueChange: (value: any) => void
}

interface ConfirmModalProps {
  open: boolean,
  onClose: (value?: any) => void,
  okButtonTitle?: string,
  okButtonColor?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
  content: string | JSX.Element | FunctionComponent<ConfirmModalFunctionComponentProps>,
  cancelButtonTitle?: string,
  title?: string,
  width?: string | number,
}

function ModalWrapper(props: ConfirmModalProps) {
  const [value, setValue] = useState<any>(true);

  const Content = props.content as any;

  return <Dialog
    sx={{'& .MuiPaper-root': {width: props.width}}}
    open={props.open}
    onClose={() => props.onClose(false)}
  >
    <DialogTitle>{props.title || 'Confirmation'}</DialogTitle>
    <DialogContent>
      {typeof props.content === 'string' && <DialogContentText>
        {props.content}
      </DialogContentText>}
      {typeof props.content === 'object' && <>{props.content}</>}
      {typeof props.content === 'function' && <Content onValueChange={setValue} />}
    </DialogContent>
    <DialogActions>
      <Button onClick={() => props.onClose(false)} color="primary" variant={'outlined'}>
        {props.cancelButtonTitle || 'Cancel'}
      </Button>
      <Button onClick={() => props.onClose(value)} color={props.okButtonColor || 'error'} variant={'contained'}>
        {props.okButtonTitle || 'Delete'}
      </Button>
    </DialogActions>
  </Dialog>
}
