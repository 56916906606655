import React from 'react';
import './App.css';
import 'overlayscrollbars/styles/overlayscrollbars.min.css';
import { ApolloProvider } from '@apollo/client';
import {Layout} from "./layout";
import {withKeycloak} from "../shared/keycloak";
import {client} from "../Apollo/EnrollmentApolloClient";
import {setErrorHandler} from "../GraphQLErrorHandler";
import {useSnackbar} from "notistack";
import {withConfirmDialog} from "../shared/ConfirmDialog/ConfirmDialogContext";
import {withChatContext} from "../Chat/lib/chat-context";
import * as _ from 'lodash';
import {BrowserRouter} from "react-router-dom";
import {
  ThemeProvider
} from "@mui/material";
import {createConfirmModal} from "../Client/components/CloseClientPrompt";
import AppTheme from "./AppTheme";

function App() {
  const { enqueueSnackbar } = useSnackbar();

  // todo refactor this!!!!!
  setErrorHandler(({ operation, response, graphQLErrors, networkError }: any) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path, extensions }: any) => {
          if (message.indexOf('The field at path') < 0) {
            switch (extensions?.status) {
              case '422': {
              }; break;
              default: {
                console.error(
                  `Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`,
                )
                enqueueSnackbar(message, {variant: 'error'});
              }
            }
          }
        }
      );
  });

  return (
    <ThemeProvider theme={AppTheme}>
      <ApolloProvider client={client}>
        <BrowserRouter getUserConfirmation={getUserConfirmation}>
          <Layout/>
        </BrowserRouter>
      </ApolloProvider>
    </ThemeProvider>
  );
}

export default _.flowRight(withKeycloak, withConfirmDialog, withChatContext)(App);

const getUserConfirmation = async (
  clientStr: string,
  callback: (ok: boolean) => void
) => {
  let answer = true;
  const client = JSON.parse(clientStr);

  if (sessionStorage.getItem('userConfirmationModalShowed') !== client.id) {
    sessionStorage.setItem('userConfirmationModalShowed', client.id)
    answer = await createConfirmModal(client);
  }

  if (answer) {
    sessionStorage.removeItem('userConfirmationModalShowed')
  }

  // do something depending on which button was pressed
  callback(answer); // allow or disallow React's navigation
};
