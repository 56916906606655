import React from "react";
import {keycloak, KeycloakRoles} from "../keycloak";

export default function useIsAdmin() {
  const isAdmin = React.useMemo(() => {
    return keycloak?.realmAccess?.roles?.includes(KeycloakRoles.ROLE_ADMIN)
  }, [keycloak])

  return isAdmin;
}
