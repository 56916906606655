import {Box, Button, CircularProgress, SxProps, Typography} from "@mui/material";
import React, {forwardRef, useCallback, useMemo} from "react";
import {SortDirection} from "../enrollment-types";

export interface DataGridProps<T, S> {
  loading?: boolean,
  columns: DataGridColumn<T, S>[];
  rows: T[]
  extraRowBuilder?: (row: T, i: number) => JSX.Element,
  getRowClassName?: (row: T, i: number) => string,
  onRowMouseEnter?: (event: any, row: T, i: number) => void,
  onRowMouseLeave?: (event: any, row: T, i: number) => void,
  rowsPerPage: number,
  page: number,
  total?: number,
  onRowClick?: (row: T) => void,
  onShowMoreClick?: () => void,
  isLastPage?: boolean,
  hidePaginator?: boolean,
  error?: string,
  sx?: SxProps,
  sort?: DataGridSortModel<S>,
  onSortModelChange?: (model?: DataGridSortModel<S>) => void,
}

function DataGrid<T, S>(props: DataGridProps<T, S>, ref: React.ForwardedRef<HTMLDivElement>) {
  const {
    loading,
    columns,
    sx,
    rowsPerPage,
    rows,
    isLastPage,
    onRowClick,
    error,
    extraRowBuilder,
    getRowClassName,
    onRowMouseEnter,
    onRowMouseLeave,
    onShowMoreClick,
    sort,
    onSortModelChange
  } = props;

  const tableStyles: any = useMemo(() => {
    return sx ? {...styles, ...sx!} : styles
  }, [sx]);

  const onSortClick = useCallback((field: S) => {
    if (onSortModelChange) {
      if (sort && sort.field === field) {
        onSortModelChange(sort.direction === SortDirection.Desc ? undefined : {field, direction: SortDirection.Desc});
        return;
      }
      onSortModelChange({field, direction: SortDirection.Asc})
    }
  }, [sort, onSortModelChange])

  return <Box sx={{position: 'relative', minHeight: 500}} ref={ref}>
    {loading && <Box sx={{position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, backgroundColor: '#ffffffa3', zIndex: 2, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <CircularProgress />
    </Box>}
    <Box sx={tableStyles} component={'table'} cellSpacing={0}>
      <thead>
        <tr>
          {columns.map((c, i) => <Box component={'td'}
                                      sx={{
                                        cursor: c.sortKey ? 'pointer' : 'default',
                                        '& .sort-icon': {
                                          opacity: c.sortKey === sort?.field ? 1 : 0
                                        },
                                        '&:hover .sort-icon': {
                                          opacity: c.sortKey === sort?.field ? 1 : .5,
                                        },
                                        width: c.width
                                      }}
                                      onClick={() => c.sortKey && onSortClick(c.sortKey)}
                                      key={'col' + i}>
            {c.title}
            {c.sortKey && <SortIcon direction={sort?.field === c.sortKey ? sort?.direction : undefined}/>}
          </Box>)}
        </tr>
      </thead>
      <tbody>
      {rows.map((row, i) => <React.Fragment key={'row' + i}>
        <tr onClick={() => onRowClick && onRowClick(row)}
            className={getRowClassName ? getRowClassName(row, i) : undefined}
            onMouseEnter={e => onRowMouseEnter && onRowMouseEnter(e, row, i)}
            onMouseLeave={e => onRowMouseLeave && onRowMouseLeave(e, row, i)}
        >
          {columns.map((c, j) => (
            <Box component={'td'} sx={{width: c.width}} key={'row' + c.title + j}>
              {c.value(row)}
            </Box>
          ))}
        </tr>
        {extraRowBuilder && extraRowBuilder(row, i)}
      </React.Fragment>)}
      {(!loading && !rows.length) && <tr className={'info'}>
        <td colSpan={columns.length}>
          <Box sx={{display: 'flex', gap: 2, alignItems: 'center', justifyContent: 'center', width: 1}}>
            {!error && <Typography sx={{mt: 23}}>No data available</Typography>}
            {!!error && <Typography sx={{mt: 23}}>An error occurred while fetching data</Typography>}
          </Box>
        </td>
      </tr>}
      </tbody>
    </Box>
    {(!loading && isLastPage === false) && <Box sx={{display: 'flex', justifyContent: 'center', mt: 3}}>
      <Button variant={'contained'} onClick={onShowMoreClick}>Show {rowsPerPage} more</Button>
    </Box>}
  </Box>
}

export default forwardRef<HTMLDivElement, DataGridProps<any, any>>(DataGrid)

const SortIcon = ({direction}: {direction?: SortDirection}) => (
  <Box component={'img'}
       sx={{mt: '3px', ml: '3px'}}
       className={'sort-icon'}
       src={direction === SortDirection.Desc ? '/img/sort-descending.svg' : '/img/sort-ascending.svg'} />
)

export interface DataGridSortModel<S> {
  field: S,
  direction: SortDirection
}

export interface DataGridColumn<T, S> {
  title: JSX.Element | string,
  value: (v: T) => JSX.Element | string,
  sortKey?: S,
  width?: number | string,
}

const styles: SxProps = {
  width: 1,
  '& td': {
    p: 1,
    fontSize: 14,
    verticalAlign: 'top',
  },
  '& tbody td': {
    fontSize: 14,
  },
  '& tbody tr': {
    '&:not(.info):hover': {
      backgroundColor: 'rgba(249,230,154,0.15)',
    },
    '&:not(:last-child) td': {
      borderBottom: '1px solid rgb(242, 244, 247)',
    },
  },
  '& thead tr': {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    '& td': {
      backgroundColor: '#F8F6F2',
      fontWeight: 600,
      color: '#000',
    }
  }
}
