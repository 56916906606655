import React from "react";
import * as yup from 'yup';
import {useFormik} from "formik";
import TextInput from "../shared/TextInput";
import {
  Box,
  Button,
  Checkbox, CircularProgress,
  FormControl,
  FormControlLabel, FormHelperText,
  Grid,
  Typography
} from "@mui/material";
import Card from "../shared/Card";
import {RouteComponentProps, useHistory} from "react-router";
import {
  AgentInput,
  AgentOutput, useAgentByContextLazyQuery
} from "../enrollment-types";
import * as _ from 'lodash';
import {keycloak, KeycloakRoles} from "../shared/keycloak";
import useAgentById from "./hooks/useAgentById";
import useSaveAgent from "./hooks/useSaveAgent";
import paths from "../router-paths";
import {useSnackbar} from "notistack";
import {useUploadAgentAvatar} from "./hooks/useUploadAgentAvatar";
import {config} from "../config/config";
import useSetDefaultAgent from "./hooks/useSetDefaultAgent";

const validationSchema = yup.object({
  firstName: yup
    .string()
    .required('First name is required'),
  lastName: yup
    .string()
    .required('Last name is required'),
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  phone: yup
    .string(),
  consultationUrl: yup.string(),
  enrollmentMeetingUrl: yup.string(),
  b2bMeetingUrl: yup.string(),
  planReviewUrl: yup.string(),
  picUrl: yup.string(),
  enabled: yup.boolean(),
  receiveEmails: yup.boolean(),
  isCSR: yup.boolean(),
});

const Form = ({input, onSubmit}: {input?: AgentInput, onSubmit: (data: AgentInput) => void}) => {
  const [prevInput, setPrevInput] = React.useState<AgentInput>();
  let history = useHistory();

  const formik = useFormik({
    initialValues: {
      firstName: input?.firstName || '',
      lastName: input?.lastName || '',
      email: input?.email || '',
      consultationUrl: input?.consultationUrl || '',
      enrollmentMeetingUrl: input?.enrollmentMeetingUrl || '',
      planReviewUrl: input?.planReviewUrl || '',
      b2bMeetingUrl: input?.b2bMeetingUrl || '',
      phone: input?.phone || '',
      receiveEmails: input?.receiveEmails || true,
      enabled: input?.enabled || true,
      isCSR: input?.enabled || false,
    },
    validationSchema: validationSchema,
    onSubmit,
  });

  React.useEffect(() => {
    formik.setValues({
      firstName: input?.firstName || '',
      lastName: input?.lastName || '',
      email: input?.email || '',
      consultationUrl: input?.consultationUrl || '',
      enrollmentMeetingUrl: input?.enrollmentMeetingUrl || '',
      b2bMeetingUrl: input?.b2bMeetingUrl || '',
      planReviewUrl: input?.planReviewUrl || '',
      phone: input?.phone || '',
      receiveEmails: input?.receiveEmails || false,
      enabled: input?.enabled || false,
      isCSR: input?.isCSR || false,
    })
  }, [input]);

  return (
    <form onSubmit={formik.handleSubmit} className={'mt-15 w-500'}>
      <TextInput
        name="firstName"
        label="First name*"
        fullWidth
        value={formik.values.firstName}
        onChange={formik.handleChange}
        error={formik.touched.firstName && Boolean(formik.errors.firstName)}
        helperText={formik.touched.firstName && formik.errors.firstName}
      />
      <TextInput
        name="lastName"
        label="Last name*"
        fullWidth
        value={formik.values.lastName}
        onChange={formik.handleChange}
        error={formik.touched.lastName && Boolean(formik.errors.lastName)}
        helperText={formik.touched.lastName && formik.errors.lastName}
      />
      <TextInput
        name="email"
        label="Email*"
        fullWidth
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
      />
      <TextInput
        name="phone"
        label="Phone"
        fullWidth
        value={formik.values.phone}
        onChange={formik.handleChange}
        error={formik.touched.phone && Boolean(formik.errors.phone)}
        helperText={formik.touched.phone && formik.errors.phone}
      />
      <TextInput
        name="consultationUrl"
        label="Calendly medicare consultation url"
        fullWidth
        value={formik.values.consultationUrl}
        onChange={formik.handleChange}
        error={formik.touched.consultationUrl && Boolean(formik.errors.consultationUrl)}
        helperText={formik.touched.consultationUrl && formik.errors.consultationUrl}
      />
      <TextInput
        name="enrollmentMeetingUrl"
        label="Calendly enrollment meeting url"
        fullWidth
        value={formik.values.enrollmentMeetingUrl}
        onChange={formik.handleChange}
        error={formik.touched.enrollmentMeetingUrl && Boolean(formik.errors.enrollmentMeetingUrl)}
        helperText={formik.touched.enrollmentMeetingUrl && formik.errors.enrollmentMeetingUrl}
      />
      <TextInput
        name="planReviewUrl"
        label="Calendly plan review meeting url"
        fullWidth
        value={formik.values.planReviewUrl}
        onChange={formik.handleChange}
        error={formik.touched.planReviewUrl && Boolean(formik.errors.planReviewUrl)}
        helperText={formik.touched.planReviewUrl && formik.errors.planReviewUrl}
      />
      <TextInput
        name="b2bMeetingUrl"
        label="B2B meeting url"
        fullWidth
        value={formik.values.b2bMeetingUrl}
        onChange={formik.handleChange}
        error={formik.touched.b2bMeetingUrl && Boolean(formik.errors.b2bMeetingUrl)}
        helperText={formik.touched.b2bMeetingUrl && formik.errors.b2bMeetingUrl}
      />
      <Box sx={{display: 'flex'}}>
        <FormControl error={formik.touched.isCSR && Boolean(formik.errors.isCSR)}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.isCSR ?? false}
                onChange={e => {
                  formik.setFieldValue('isCSR', e.target.checked);
                }}
                color="primary"
              />
            }
            label="Is CSR"
          />
          {(formik.touched.enabled && formik.errors.enabled) && <FormHelperText>{formik.errors.enabled}</FormHelperText>}
        </FormControl>
        <FormControl error={formik.touched.enabled && Boolean(formik.errors.enabled)}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.enabled ?? false}
                onChange={e => {
                  formik.setFieldValue('enabled', e.target.checked);
                }}
                color="primary"
              />
            }
            label="Enabled"
          />
          {(formik.touched.enabled && formik.errors.enabled) && <FormHelperText>{formik.errors.enabled}</FormHelperText>}
        </FormControl>
        <FormControl className={'ml-5'} error={formik.touched.receiveEmails && Boolean(formik.errors.receiveEmails)}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.receiveEmails ?? false}
                onChange={e => {
                  formik.setFieldValue('receiveEmails', e.target.checked);
                }}
                color="primary"
              />
            }
            label="Receive emails"
          />
          {(formik.touched.receiveEmails && formik.errors.receiveEmails) && <FormHelperText>{formik.errors.receiveEmails}</FormHelperText>}
        </FormControl>
      </Box>
      <Grid container alignItems={"center"} justifyContent={"space-between"} direction={'row'} className={'mt-10'}>
        <Grid item>
          <Button variant={'outlined'} onClick={() => history.goBack()}>CANCEL</Button>
        </Grid>
        <Grid item>
          <Button variant={'contained'} color={'primary'} type={'submit'}>SAVE</Button>
        </Grid>
      </Grid>
    </form>
);
}

const AgentForm = ({ match: { params } }: RouteComponentProps<{id?: string}>) => {
  const [getAgentByContext] = useAgentByContextLazyQuery();
  const [getAgentByid] = useAgentById();
  const [saveAgent] = useSaveAgent({
    onCompleted: () => {
      if (isAgentManager) {
        history.push(paths.agents);
      }
      enqueueSnackbar('Agent saved!', {variant: "success"})
    },
    onError: error => {
      const message = error.graphQLErrors.at(0)?.message || 'Error';
      enqueueSnackbar(message, {variant: "error"})
      console.error(error);
    }
  });
  const [setDefaultAgent] = useSetDefaultAgent();
  const [initialData, setInitialData] = React.useState<Partial<AgentOutput>>();
  const [picUrl, setPicUrl] = React.useState<string>();
  const isAgentManager = keycloak?.realmAccess?.roles?.includes(KeycloakRoles.ROLE_AGENT_MANAGER)
  let history = useHistory();
  const {enqueueSnackbar} = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [upload] = useUploadAgentAvatar()
  const ref = React.useRef<any>();

  React.useEffect(() => {
    if (isAgentManager) {
      if (params.id) {
        getAgentByid({variables: {id: params.id}}).then(response => {
          setInitialData(response.data?.agentById);
          setPicUrl(response.data?.agentById?.picUrl || undefined);
        });
      }
    } else {
      getAgentByContext().then(response => {
        setInitialData(response.data?.agentByContext);
        setPicUrl(response.data?.agentByContext?.picUrl || undefined);
      });
    }
  }, [params])

  const save = React.useCallback((values: AgentInput) => {
    saveAgent({
      variables: {
        input: {
          id: params.id || initialData?.id,
          ...values,
          picUrl
        }
      }
    })
  }, [params, initialData, picUrl])

  const onUpload = (file: any) => {
    setLoading(true)
    upload({variables: {img: file}})
      .then((res) => {
        setPicUrl(res.data?.uploadAgentAvatar)
        enqueueSnackbar('File uploaded!', {variant: "info"});
      })
      .catch(e => {
        enqueueSnackbar('File upload error', {variant: "error"})
      })
      .finally(() => {
        if (ref?.current) {
          ref.current.value = '';
        }
        setLoading(false)
      })
  }

  const setDefault = () => {
    setDefaultAgent({variables: {agentId: initialData!.id}})
      .then(() => setInitialData(data => ({...data, isDefault: true})))
  }

  return <Card>
    <Box sx={{display: 'flex', alignItems: 'center', width: 500, justifyContent: 'space-between'}}>
      <Typography color={'textPrimary'} variant={'h2'} className={'medium'}>{params.id ? 'Edit' : 'Create'} agent</Typography>
      {initialData && <>
        {!initialData?.isDefault && <Button variant={'outlined'} onClick={setDefault}>Set agent as default</Button>}
        {initialData?.isDefault && <Typography color={'textSecondary'} variant={'h4'} className={'medium'}>Default agent</Typography>}
      </>}
    </Box>
    <Box sx={{display: 'flex', alignItems: 'flex-start'}}>
      <Form input={initialData} onSubmit={save} />
      <Box ml={2} mt={'16px'}>
        <Box sx={{
          borderRadius: '4px',
          position: 'relative',
          height: 100,
          width: 100,
          mb: 2
        }} style={{backgroundImage: `url("${picUrl ? config.cdnUrl + picUrl : '/img/no-image-found.png'}")`, backgroundSize: 'cover', backgroundPosition: 'center',}} />
        {!loading && <>
          <input ref={ref} accept="image/png, image/gif, image/jpeg"
                 onChange={(e: React.ChangeEvent<HTMLInputElement>) => onUpload((e?.target?.files as any)[0])}
                 type="file" />
        </>}
        {loading && <CircularProgress className={'ml-20'} />}
      </Box>
    </Box>
  </Card>
};

export default AgentForm;
