// @flow
import * as React from 'react';
import {Typography} from "@mui/material";
import {Check, Close} from "@mui/icons-material";
import {Link} from "react-router-dom";
import {AgentAvailabilityStateOutput} from "../../../enrollment-types";
import DataGrid, {DataGridColumn} from "../../../shared/DataGrid";
import paths from "../../../router-paths";

type Props = {
  rows: AgentAvailabilityStateOutput[],
  loading: boolean,
};

export function RoundRobinAgents(props: Props) {
  const {loading, rows} = props;

  return (
    <DataGrid columns={columns}
              page={0}
              hidePaginator
              rowsPerPage={1000}
              loading={loading}
              rows={rows}/>
  );
}


const columns: DataGridColumn<AgentAvailabilityStateOutput, keyof AgentAvailabilityStateOutput>[] = [
  {
    title: 'Email',
    value: v => v.agentEmail,
  },
  {
    title: 'DontDisturb',
    value: v => v.dontDisturb ? <Check color={'success'} /> : <Close color={'error'} />
  },
  {
    title: 'Excluded',
    value: v => v.excludedFromRoundRobin ? 'Yes' : 'No'
  },
  {
    title: 'Available For New Leads',
    value: v => v.isAvailableForNewLeads ? 'Yes' : 'No'
  },
  {
    title: 'On call',
    value: v => v.onCall ? (
      <Typography component={Link}
                  color={'textPrimary'}
                  sx={{textDecoration: 'underline'}}
                  to={paths.client + '/' + v.onCallWithClient?.clientId}>
        With ${v.onCallWithClient?.clientName}
      </Typography>
    ) : <Close color={'error'} />
  },
  {
    title: 'Online',
    value: v => v.online ? <Check color={'success'} /> : <Close color={'error'} />
  },

]