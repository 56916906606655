import React, {useEffect} from "react";
import {useFormik} from "formik";
import {
  Box,
  Button,
  Divider,
  ListItemText,
  MenuItem, Typography
} from "@mui/material";
import moment from "moment";
import {
  getEnrollmentTypeDescription,
  getEnrollmentTypeSubtitle,
  getEnrollmentTypeTitle
} from "../lib";
import {FormikTextInput} from "../../../../../../shared/formik-text-input";
import {
  EnrollmentListItemOutput,
  EnrollmentStatusDto, MedicareEnrollmentType,
  PlanYear,

} from "../../../../../../enrollment-types";
import AgentSelector from "../../../../../../Agent/AgentSelector";
import {FormikSelectInput} from "../../../../../../shared/formik-select-input";
import {FormikDateInput} from "../../../../../../shared/formik-date-input";
import {IEnrollmentForm} from "../../../lib/types";
import {ZipSelector} from "../../../../../../features/zip-selector";
import {MaCarrierSelector} from "../../carrier-selector";
import {fillFormData, isEffectiveDateValid, maEnrollmentFormValidationSchema as validationSchema} from "../../../lib/lib";
import {MaPlanSelector} from "../../PlanSelector";

interface FormProps {
  input?: Partial<EnrollmentListItemOutput>,
  onSubmit: (data: IEnrollmentForm) => void,
  onCancel: () => void,
  loading: boolean,
}

export const MaEnrollmentForm = ({input, onSubmit, onCancel}: FormProps) => {
  const formik = useFormik({
    initialValues: fillFormData(input, validationSchema.default()),
    validationSchema,
    onSubmit,
  });

  const reset = (input: any) => {
    formik.resetForm({
      errors: [] as any,
      values: fillFormData(input, formik.values),
    })
  }

  useEffect(() => {
    if (input) {
      reset(input)
    }
  }, [input]);

  useEffect(() => {
    if (formik.values.zip.length && formik.values.zip.length < 5) {
      formik.setFieldValue('county', '');
      formik.setFieldValue("bidId", '');
      formik.setFieldValue("planYear", '');
      formik.setFieldValue("planName", '');
      formik.setFieldValue("carrierName", '');
    }
  }, [formik.values.zip]);

  const effectiveDate = formik.values.effectiveDate || input?.effectiveDate;
  const planYear = isEffectiveDateValid(effectiveDate) ? 'Year' + moment(effectiveDate).year() as PlanYear : undefined

  return (
    <form onSubmit={formik.handleSubmit} className={'mt-15'}>
      <Box sx={{display: 'flex', gap: 1}}>
        <FormikTextInput formik={formik}
                         fullWidth
                         name={'firstName'} />
        <FormikTextInput formik={formik}
                         fullWidth
                         name={'lastName'} />
      </Box>
      <Box sx={{display: 'flex', gap: 1}}>
        <FormikDateInput formik={formik}
                         name={'birthDate'} />
        <FormikTextInput formik={formik}
                         fullWidth
                         name={'phoneNumber'} />
      </Box>
      <FormikTextInput formik={formik}
                       fullWidth
                       name={'email'} />
      <Divider />
      <Box sx={{display: 'flex', mt: 3, gap: 1}}>
        <FormikDateInput formik={formik}
                         onChange={value => {
                           if (value === null || value > new Date(2000, 1, 1)) {
                             formik.setFieldValue("effectiveDate", value)
                           }
                         }}
                         name={'effectiveDate'} />
        <FormikDateInput formik={formik}
                         onChange={value => {
                           if (value === null || value > new Date(2000, 1, 1)) {
                             formik.setFieldValue("applicationDate", value)
                           }
                         }}
                         name={'applicationDate'} />
      </Box>

      <ZipSelector zip={formik.values.zip}
                   county={formik.values.county}
                   zipError={formik.touched.zip ? formik.errors.zip : undefined}
                   countyError={formik.touched.county ? formik.errors.county : undefined}
                   onChange={data => {
                     formik.setFieldValue('zip', data.zip);
                     formik.setFieldValue('county', data.county);
                     if (formik.values.county !== data.county) {
                       formik.setFieldValue("bidId", '');
                       formik.setFieldValue("planYear", '');
                       formik.setFieldValue("planName", '');
                       formik.setFieldValue("carrierName", '');
                     }
                   }} />

      <Box sx={{display: 'flex', gap: 1}}>
        <MaCarrierSelector zip={formik.values.zip}
                         county={formik.values.county}
                         value={formik.values.carrierName}
                         disabled={!(formik.values.zip && formik.values.county && formik.values.effectiveDate)}
                         onChange={val => formik.setFieldValue("carrierName", val)}
                         planYear={planYear}
        />

        <FormikSelectInput formik={formik} name={'status'}>
          <MenuItem value={EnrollmentStatusDto.Draft}>Draft</MenuItem>
          <MenuItem value={EnrollmentStatusDto.Enrolled}>Enrolled</MenuItem>
          <MenuItem value={EnrollmentStatusDto.New}>New</MenuItem>
          <MenuItem value={EnrollmentStatusDto.Rejected}>Rejected</MenuItem>
          <MenuItem value={EnrollmentStatusDto.Sent}>Sent</MenuItem>
        </FormikSelectInput>
      </Box>

      <MaPlanSelector zip={formik.values.zip}
                    county={formik.values.county}
                    value={formik.values.bidId}
                    onChange={plan => {
                      formik.setFieldValue("bidId", plan.bidId);
                      formik.setFieldValue("planYear", plan.planYear);
                      formik.setFieldValue("planName", plan.planName);
                    }}
                    planYear={planYear}
                    error={formik.touched.bidId ? formik.errors.bidId : undefined}
                    disabled={!formik.values.carrierName}
                    carrierName={formik.values.carrierName}
      />

      <AgentSelector error={formik.touched.agentId && Boolean(formik.errors.agentId) ? formik.errors.agentId as string : undefined}
                     value={formik.values.agentId || undefined}
                     onChange={id => formik.setFieldValue("agentId", id)} />

      <FormikSelectInput formik={formik}
                         sx={{
                           '& p': {display: 'none'},
                           '& .MuiListItemText-primary': {
                             overflow: 'hidden',
                             textOverflow: 'ellipsis'
                           }
                         }}
                         onChange={event => {
                           formik.setFieldValue("enrollmentType", event.target.value as string);
                         }}
                         name={'enrollmentType'}>
        {[MedicareEnrollmentType.MaNew, MedicareEnrollmentType.MaUnlikeChange, MedicareEnrollmentType.MaReplacement].map(type => (
          <MenuItem key={type} onClick={() => {
            formik.setFieldValue("enrollmentType", type)
          }} value={type as string}>
            <ListItemText primary={getEnrollmentTypeTitle(type)} secondary={getEnrollmentTypeSubtitle(type)} />
          </MenuItem>
        ))}
      </FormikSelectInput>

      {!!formik.values['enrollmentType'] && <Typography color={'textPrimary'} variant={'body1'} className={'mb-20'}>
        {getEnrollmentTypeDescription(formik.values['enrollmentType'] as MedicareEnrollmentType)}
      </Typography>}

      <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
        <Button variant={'outlined'} onClick={onCancel}>CANCEL</Button>
        <Button variant={'contained'} color={'primary'} type={'submit'}>SAVE</Button>
      </Box>
    </form>
  );
}