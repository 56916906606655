import * as React from 'react';
import {
  Box,
  Button,
  Dialog,
  IconButton,
  MenuItem,
  Typography
} from "@mui/material";
import {colors} from "../../../app/AppTheme";
import {DrugInfoWithPackagesFlags} from "../../../types";
import {makeStyles, createStyles} from "@mui/styles";
import FormSelect from "../../FormSelect";

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      textAlign: 'center',
      lineHeight: '36px',
      fontWeight: 700,
    },
    subtitle: {
      textAlign: 'center',
      lineHeight: '22px',
      fontWeight: 500,
    },
    link: {
      color: colors.green.variant1,
      textAlign: 'center',
      cursor: 'pointer',
      textDecoration: 'underline'
    },
    icon: {
      position: 'absolute!important' as any,
      top: 8,
      right: 8,
    },
    select: {
      '& .MuiOutlinedInput-input': {
        color: colors.green.variant2 + '!important',
        padding: '15px 20px 14px!important',
        fontSize: 22
      },
    }
  })
);

interface NoPackagesModalProps {
  open: boolean,
  info?: DrugInfoWithPackagesFlags,
  onClose: (switchTo?: {name: string, rxcui: string }) => void,
}

export default function NoPackagesModal(props: NoPackagesModalProps) {
  const classes = useStyles();

  const [brandedRxcui, setBrandedRxcui] = React.useState<string>();

  React.useEffect(() => {
    setBrandedRxcui((props?.info?.brandedDetails[0]?.rxcui as string) || undefined)
  }, [props.open]);

  if (!props.info) {
    return null;
  }

  const hasPackages = props.info.hasBrandedPackages || props.info.hasGenericPackages;

  const buttonText = hasPackages ? `Add ${props.info.isGeneric ? 'branded' : 'generic'} drug` : `Back to drug search`;

  const submit = () => {
    if (props.info?.isGeneric) {
      if (props.info.brandedDetails.length === 1) {
        props.onClose({rxcui: props.info?.brandedDetails[0].rxcui as string, name: props.info?.brandedDetails[0].name} )
      } else {
        const drug = props.info?.brandedDetails.find(d => d.rxcui === brandedRxcui);
        if (drug) {
          props.onClose({rxcui: drug.rxcui, name: drug.name})
        } else {
          props.onClose();
        }
      }
    } else {
      props.onClose({rxcui: props.info?.genericRxcui as string, name: props.info?.genericName as string})
    }
  }

  return <Dialog
    open={props.open}
    onClose={() => props.onClose()}
    PaperProps={{
      className: 'p-30'
    }}
  >
    <>
      <IconButton onClick={() => props.onClose()} className={classes.icon}><img src={'/img/icon-close.svg'} /></IconButton>
      <Typography className={'fs-26 lh-32 bold'} align={'center'}>{props.info.name}</Typography>
      <Typography className={'fs-18 lh-22 mt-8'} align={'center'}>isn’t currently available</Typography>
      <div className={'h-12'} />
      {hasPackages && <>
        {!props.info.isGeneric && <>
          <Typography className={'fs-18 lh-22 mv-8'} align={'center'}>It has a lower cost generic version called:</Typography>
          <Typography className={'fs-25 lh-31 bold'} align={'center'} >{props.info.genericName}</Typography>
        </>}
        {(!!props.info.isGeneric && props.info.brandedDetails.length === 1) && <>
          <Typography className={'fs-18 lh-22 mv-8'} align={'center'}>It has a lower cost branded version called:</Typography>
          <Typography className={'fs-25 lh-31 bold'} align={'center'} >{props.info.brandedDetails[0].name}</Typography>
        </>}
        {(!!props.info.isGeneric && props.info.brandedDetails.length > 1) && <>
          <Typography variant={'h3'} color={'textSecondary'} className={`${classes.subtitle} mb-10`}>You can instead select from the following<br/> branded drugs:</Typography>
          <FormSelect
            fullWidth
            value={brandedRxcui}
            placeholder={'Select drug'}
            className={classes.select}
            onChange={event => {
              setBrandedRxcui(event.target.value as string);
            }}
          >
            {
              props.info.brandedDetails.map(drug => <MenuItem key={drug.rxcui} value={drug.rxcui}>{drug.name}</MenuItem>)
            }
          </FormSelect>
        </>}
        <div className={'h-12'} />
        <Typography className={'fs-25 lh-31 medium'} align={'center'}>Would you like to add the {props.info.isGeneric ? 'branded' : 'generic'}<br /> version to your list instead?</Typography>
      </>}
      {!hasPackages && <Typography className={'fs-25 lh-31 medium'} align={'center'}>Speak to one our advisors if this<br />drug is important to you.</Typography>}
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'} mt={'10px'}>
        {hasPackages && <Button variant={'contained'}
                                className={'w-290'}
                                onClick={() => {
                                  if (!hasPackages) {
                                    props.onClose();
                                  } else {
                                    submit()
                                  }
                                }}>
                          {buttonText}
                        </Button>}
          <Box pt={'20px'}>
          <Typography variant={'h4'} className={`${classes.link} branded-drug-button`} onClick={() => props.onClose()}>Close</Typography>
        </Box>
      </Box>
    </>
  </Dialog>
}
