// @flow
import * as React from 'react';
import {Box, Tab, Tabs, Typography} from "@mui/material";
import Card from "../../shared/Card";
import {
  useAgentCallAgendaWholeStateSubscription
} from "../../enrollment-types";
import {RoundRobinAgents} from "./ui/RoundRobinAgents";
import {RoundRobinTasks} from "./ui/RoundRobinTasks";


export function RoundRobin() {
  const {loading, data} = useAgentCallAgendaWholeStateSubscription();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const agents = data?.agentCallAgendaWholeState.agentsSorted || [];
  const tasks = data?.agentCallAgendaWholeState.tasks || [];

  return (
    <Card padding={0}>
      <Box display={'flex'} p={'30px 30px 16px'} justifyContent={'space-between'} alignItems={'top'} width={'100%'}>
        <Typography color={'textPrimary'} variant={'h4'}>Round Robin state</Typography>
      </Box>

      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Agents" />
          <Tab label="Tasks" />
        </Tabs>
      </Box>

      {!value && <RoundRobinAgents loading={loading} rows={agents} />}
      {value && <RoundRobinTasks loading={loading} rows={tasks} />}

    </Card>
  );
}

