import * as React from 'react';
import {DrugInfoWithGeneric} from "../../types";
import {ListItem, ListItemText, SxProps, Typography} from "@mui/material";

interface DrugLineProps {
  drug: DrugInfoWithGeneric,
  onClick?: () => void,
  sx?: SxProps,
}

export const DrugLine = (props: DrugLineProps) => {
  return <ListItem sx={props.sx} dense disableGutters onClick={props.onClick}>
    <ListItemText
      primary={props.drug.name}
      secondary={
        <Typography color={'textSecondary'}>{''}</Typography>
      }
    />
  </ListItem>
};
