import FormSelect from "../../shared/FormSelect";
import {Box, MenuItem, SelectChangeEvent} from "@mui/material";
import React, {useEffect} from "react";
import TextInput from "../../shared/TextInput";
import useZipCounties, {ICity} from "./api/useZipCounties";

interface Props {
  zip: string,
  county: string,
  zipError?: string,
  countyError?: string,
  onChange: (data: {zip: string, county: string, state: string}) => void,
}

export function ZipSelector({zip, county, zipError, countyError, onChange}: Props) {
  const [counties, setCounties] = React.useState<ICity[]>([]);

  const [getCounties] = useZipCounties()

  useEffect(() => {
    if (zip.length === 5) {
      if (!county || !counties.some(c => c.countyname === county)) {
        getCounties({variables: {zip}})
          .then(res => {
            const resCounties = res.data?.plans_zip_county_fips;
            if (resCounties) {
              if (resCounties.length === 1) {
                onChange({
                  zip, county: resCounties[0].countyname, state: resCounties[0].state,
                })
              }
              setCounties(resCounties);
            } else {
              setCounties([]);
            }
          })
          .catch(() => setCounties([]))
      }
    } else {
      setCounties([])
    }
  }, [getCounties, zip, county, counties]);

  const onCountyChange = (event: SelectChangeEvent<unknown>) => {
    const county = event.target.value as string;
    if (county) {
      const state = counties.find(c => c.countyname === county)?.state || ''
      onChange({zip, county, state})
    }
  }

  return <Box sx={{display: 'flex', gap: 1}}>
    <TextInput
      fullWidth
      name="zip"
      label="Zip"
      value={zip}
      onChange={event => onChange({zip: event.target.value, county: '', state: ''})}
      error={Boolean(zipError)}
      helperText={Boolean(zipError) && zipError}
    />
    <FormSelect label={'County'}
                value={county}
                onChange={onCountyChange}
                error={Boolean(countyError)}
                helperText={countyError ? countyError : undefined}
    >
      {counties.map(c => <MenuItem key={c.countyname} value={c.countyname}>{c.countyname}</MenuItem>)}
    </FormSelect>
  </Box>
}