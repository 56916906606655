import React, {ChangeEvent, useMemo, useRef, useState} from "react";
import {Box, Button, Chip, Typography} from "@mui/material";
import paths from "../../router-paths";
import {useHistory} from "react-router";
import Card from "../../shared/Card";
import {
  AgentCallAgendaTaskAssignmentTypeOutput,
  AgentCallAgendaTaskOutput,
  AssignedTagViewOutput,
  CallHistoryOutput,
  CallHistoryResultOutput,
  ClientViewFilterInput,
  ClientViewSortInput,
  ClientViewsQuery, StatusDto,
  useAgentCallAgendaWholeStateSubscription,
  useClientViewsLazyQuery
} from "../../enrollment-types";
import FilterListIcon from '@mui/icons-material/FilterList';
import TextInput from "../../shared/TextInput";
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from '@mui/icons-material/Close';
import {StatusView} from "../../features";
import moment from "moment";
import {getAgentDisplayName} from "../../Agent/AgentSelector";
import useClientListSorting from "./lib/useClientListSorting";
import useClientListPage from "./lib/useClientListPage";
import useClientListSearchTerm from "./lib/useClientListSearchTerm";
import {DataGrid, GridColDef, GridValueGetterParams} from "@mui/x-data-grid";
import {getTagLabel} from "../../Client/widgets/ClientTags";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import * as _ from 'lodash';
import useClientListPageSize from "./lib/useClientListPageSize";
import {formatPhoneLink} from "../../shared/utils";
import {Link} from "react-router-dom";
import {colors} from "../../app/AppTheme";
import {assignCallsToClients, getUserName, WithCalls} from "../../shared/tools";
import ClientListFilterDrawer from "./ui/ClientListFilterDrawer";
import useSessionStorageState from "../../shared/useSessionStorageState";
import ClientListFilterBadges from "./ui/ClientListFilterBadges";
import {useDebouncedEffect} from "../../shared/hooks/useDebouncedEffect";
import {getUserId} from "../../shared/keycloak";
import {ClientListTimer} from "./ui/ClientListTimer";

const columns: GridColDef[] = [
  { field: 'firstName', headerName: 'Name', width: 150, disableColumnMenu: false,
    renderCell: params => {
      return <Link style={{color: colors.text.primary}} onClick={event => event.stopPropagation()} to={'/client/' + params.row.id}>{getUserName(params.row)}</Link>
    }
  },
  { field: 'email', headerName: 'Email', width: 200, disableColumnMenu: true,
    renderCell: params => {
      return <Link style={{color: colors.text.primary}} onClick={event => event.stopPropagation()} to={'/client/' + params.row.id}>{params.row.email}</Link>
    }
  },
  { field: 'phoneNumber', headerName: 'Phone', width: 140, disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) => {
      if (params.row.phoneNumber) {
        return formatPhoneLink(params.row.phoneNumber, true)
      }
      return ''
    }
  },
  { field: 'birthDate', headerName: 'BirthDate', width: 150, disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) => {
      const birthDateStr = params.row.birthDate as string;
      if (birthDateStr) {
        return moment(birthDateStr).format('L')
      }
      return ''
    }
  },
  { field: 'age', headerName: 'Age', width: 100, disableColumnMenu: true, sortable: false,
    valueGetter: (params: GridValueGetterParams) => {
      const birthDateStr = params.row.birthDate as string;
      if (birthDateStr) {
        return Math.floor(moment.duration(moment().diff(moment(birthDateStr))).asYears())
      }
      return ''
    }
  },
  { field: 'lastCall', headerName: 'Most Recent Call', width: 200, sortable: false, disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) => {
      const lastCallStr = params.row.lastCall?.createdAt;
      return lastCallStr ? moment(lastCallStr).format('L H:mm:ss') : ''
    }
  },
  { field: 'userId', headerName: 'Auth', width: 70, sortable: false, disableColumnMenu: true,
    renderCell: params =>
      params.row.userId ? <CheckIcon style={{color: 'green'}} fontSize={'small'} /> : <ClearIcon style={{color: 'red'}} fontSize={'small'} />
  },
  { field: 'state', headerName: 'State', width: 70, disableColumnMenu: true, sortable: false},
  { field: 'agent', headerName: 'Agent responsible', width: 150, sortable: false,
    valueGetter: (params: GridValueGetterParams) => {
      const agent = params.row.agent;
      if (agent) {
        return getAgentDisplayName(agent)
      } else {
        return '-'
      }
    }
  },
  { field: 'status', headerName: 'Status', width: 200, disableColumnMenu: true,
    renderCell: params => {
      return <StatusView status={params.row.status || undefined} />
    }
  },
  { field: 'cId', headerName: 'Time since creation', width: 200, sortable: false, disableColumnMenu: true,
    renderCell: params => {
      const createdAtStr = params.row.createdAt as string;
      const status = params.row.status;
      if (createdAtStr && status === StatusDto.NotYetEngaged) {
        return <ClientListTimer createdAt={createdAtStr} />
      }
      return <></>
    }
  },
  { field: 'calls', headerName: 'Calls', width: 70, sortable: false, disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) => {
      const calls = params.row.calls;
      if (calls) {
        return calls.filter((c: CallHistoryOutput) => c.result === CallHistoryResultOutput.SuccessfulSeeNotesForCallDetails).length + '/' + calls.length
      }
      return ''
    }
  },
  { field: 'createdAt', headerName: 'Quote submitted timestamp', width: 200, disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) => {
      const createdAtStr = params.row.createdAt as string;
      if (createdAtStr) {
        return moment(createdAtStr).format('L H:mm:ss')
      }
      return ''
    }
  },
  { field: 'followUpDate', headerName: 'Next contact date', width: 200, disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) => {
      const followUpDateStr = params.row.followUpDate;
      if (followUpDateStr) {
        return moment(followUpDateStr).format('L')
      }
      return ''
    }
  },
  { field: 'expectedCloseDate', headerName: 'Expected close date', width: 200, disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) => {
      if (params.row.expectedCloseDate) {
        return moment(params.row.expectedCloseDate).format('L')
      }
      return ''
    }
  },
  { field: 'tags', headerName: 'Tags', width: 200, disableColumnMenu: true,
    renderCell: params => {
      return <div className={'chips-wrapper'}>
        {params.row.tags.map((value: AssignedTagViewOutput) => (
          <Chip style={{backgroundColor: value.tag.color as string}} className={'ml-8 mt-5'} size="small" key={value.tag.id} label={getTagLabel(value)}  />
        ))}
      </div>
    }
  },
  { field: 'affiliationPartnershipSource', headerName: 'Lead Sources', width: 200, disableColumnMenu: true},
  { field: 'profileSource', headerName: 'Profile Source', width: 200, disableColumnMenu: true,
    renderCell: params => {
      return <Typography color={'textPrimary'}>{ _.startCase(_.lowerCase(params.row.profileSource))}</Typography>
    }
  },
  { field: 'adviserName', headerName: 'Advisor', width: 200, disableColumnMenu: true},
];

const ClientList = () => {
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [filters, setFilters] = useClientListFilters();
  const [loading, setLoading] = useState(false)

  const [page, setPage] = useClientListPage();
  const [sorting, setSorting] = useClientListSorting();
  let history = useHistory();
  const [searchTerm, setSearchTerm] = useClientListSearchTerm();
  const [pageSize, setPageSize] = useClientListPageSize();
  const [rows, setRows] = React.useState<WithCalls<ClientViewsQuery['clientViews']['data'][0]>[]>([]);

  const [searchRequest, {data}] = useClientViewsLazyQuery({
    fetchPolicy: 'no-cache'
  });

  const clientsToCallCount = useRef(0)

  const {data: callAgendaData} = useAgentCallAgendaWholeStateSubscription({
    onData: res => {
      const assignedClientsCall = res.data.data?.agentCallAgendaWholeState.tasks.filter(t => t.currentAssignment.assignedAgentId === getUserId()).map(t => t.clientId).length || 0;
      if (assignedClientsCall && assignedClientsCall !== clientsToCallCount.current) {
        search()
      }
      clientsToCallCount.current = assignedClientsCall;
    }
  });

  const activeTasks: AgentCallAgendaTaskOutput[] = callAgendaData?.agentCallAgendaWholeState.tasks.filter(t => !t.completed) || [];
  const clientIdsToCall: string[] = activeTasks.filter(t => t.currentAssignment.assignedAgentId === getUserId()).map(t => t.clientId) || [];

  const tasksAssignedToOtherAgents: AgentCallAgendaTaskOutput[] = activeTasks.filter(t => t.currentAssignment.assignedAgentId !== getUserId()) || [];

  React.useEffect(() => {
    search();
  }, [page, sorting, pageSize]);

  const search = async (filterInput?: ClientViewFilterInput) => {
    setLoading(true);
    setRows([]);
    const clients = await searchRequest({
      variables: {
        filterInput: filterInput || filters,
        pageInput: {
          page: page,
          size: pageSize
        },
        sort: sorting && {
          field: sorting.field,
          direction: sorting.sort
        } as ClientViewSortInput
      }
    }).then(res => {
      return res.data?.clientViews.data || []
    });

    if (clients.length) {
      setRows(await assignCallsToClients(clients))
    }
    setLoading(false);
  }

  useDebouncedEffect(() => {
    setFilters(prev => {
      if (prev?.searchTerm === searchTerm) {
        return prev;
      }

      const result = {...prev, searchTerm};
      if (page > 0) {
        setPage(0);
      } else {
        search(result);
      }
      return result
    })
  }, 400, [searchTerm])

  const onFilterClose = (value?: ClientViewFilterInput) => {
    if (value) {
      setFilters(value)
      if (page > 0) {
        setPage(0);
      } else {
        search(value);
      }
    }
    setFiltersOpen(false);
  }

  const onFilterRemoveClick = (filterName: keyof ClientViewFilterInput) => {
    setFilters(prev => {
      const result = {...prev};
      delete result[filterName];

      if (filterName === "searchTerm") {
        setSearchTerm('');
      } else {
        if (page > 0) {
          setPage(0);
        } else {
          search(result);
        }
      }

      return result
    })
  }

  const rowsWithAssignedRRAgents = useMemo(() => {
    if (!tasksAssignedToOtherAgents.length) {
      return rows
    }
    return rows.map(r => {
      const assignedRRTask = tasksAssignedToOtherAgents.find(t => t.clientId === r.id)
      let assignedRRAgentName: string;

      switch (assignedRRTask?.currentAssignment.type) {
        case AgentCallAgendaTaskAssignmentTypeOutput.Agent: assignedRRAgentName = assignedRRTask.currentAssignment.assignedAgentName + '  (RR assigned)'; break;
        case AgentCallAgendaTaskAssignmentTypeOutput.Csr: assignedRRAgentName = 'CSR (RR assigned)'; break;
        default: assignedRRAgentName = '';
      }

      return {
        ...r,
        agent: r.agent ? r.agent : assignedRRAgentName ? {firstName: assignedRRAgentName} : undefined,
      }
    })
  }, [tasksAssignedToOtherAgents, rows])

  return <Card padding={0}>
    <ClientListFilterDrawer open={filtersOpen} onClose={onFilterClose} initial={filters} />
    <Box display={'flex'} p={'30px'} justifyContent={'space-between'} alignItems={'top'} width={'100%'}>
      <Box display={'flex'} alignItems={'center'} gap={5}>
        <Typography color={'textPrimary'} variant={'h4'}>Client list</Typography>
        <Button variant={'contained'} size={'small'} color={'primary'} onClick={() => history.push(paths.createClient)}>Create new client</Button>
      </Box>
      <Box>
        <Box display={'flex'} alignItems={'center'} gap={1}>
          <TextInput
            className={'mb-0 w-380'}
            fullWidth
            name="search"
            label="Search"
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  {!!searchTerm && <CloseIcon cursor={'pointer'} onClick={() => {
                    setSearchTerm('');
                  }} fontSize={'small'} color={'inherit'} />}
                </InputAdornment>,
            }}
            value={searchTerm}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value || '')}
          />
          <Button variant={'contained'} size={'small'} startIcon={<FilterListIcon />}
                  onClick={() => setFiltersOpen(true)}>
            Filters
          </Button>
        </Box>
      </Box>
    </Box>
    <ClientListFilterBadges onRemoveFilterClick={onFilterRemoveClick} filter={filters} />
    <div style={{ height: 'calc(100vh - 270px)', width: '100%' }}>
      <DataGrid rows={rowsWithAssignedRRAgents}
                paginationMode={"server"}
                sortingMode={'server'}
                columns={columns}
                loading={loading}
                disableRowSelectionOnClick
                paginationModel={{
                  page,
                  pageSize
                }}
                getRowClassName={params => {
                  if (clientIdsToCall.includes(params.row.id)) {
                    return 'warning-data-grid-row'
                  }
                  if (tasksAssignedToOtherAgents.some(t => t.clientId === params.row.id)) {
                    return 'deactivated-data-grid-row'
                  }
                  return ''
                }}
                onPaginationModelChange={model => {
                  setPage(model.page);
                  setPageSize(model.pageSize)
                }}
                onRowClick={(param, event) => {
                  history.push(paths.client + '/' + param.row.id)
                }}
                sortModel={sorting ? [sorting] : []}
                onSortModelChange={(sortModel) => {
                  setSorting(sortModel[0])
                }}
                pageSizeOptions={[20, 50, 100]}
                rowCount={parseFloat(data?.clientViews?.totalElements || '0')}
                pagination
      />
    </div>
  </Card>;
}

export default ClientList;

const useClientListFilters = () => {
  return useSessionStorageState<ClientViewFilterInput | undefined>(
    'clientListFilters',
    undefined
  )
}