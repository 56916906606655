import {
  ClientGroupItemOutput, ClientViewSortInput,
  ClientViewsQuery,
  Field,
  SortDirection,
  useClientViewsLazyQuery
} from "../../../../../enrollment-types";
import React from "react";
import {useDebouncedEffect} from "../../../../../shared/hooks/useDebouncedEffect";
import AscendAutocomplete from "../../../../../shared/AscendAutocomplete";
import {ClientGroupLine} from "./ClientGroupLine";
import TextInput from "../../../../../shared/TextInput";
import InputAdornment from "@mui/material/InputAdornment";
import {Button, CircularProgress, Grid, List, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface ClientGroupsFormProps {
  error: any,
  input: ClientGroupItemOutput[];
  onCancel: () => void;
  onSubmit: (data: ClientGroupItemOutput[]) => void;
}

export const ClientGroupsForm = (props: ClientGroupsFormProps) => {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [searchRequest, {loading}] = useClientViewsLazyQuery({
    onCompleted: data => setOptions(data.clientViews?.data),
    fetchPolicy: 'no-cache'
  });
  const [clients, setClients] = React.useState<ClientGroupItemOutput[]>(props.input);
  const [options, setOptions] = React.useState<ClientViewsQuery['clientViews']['data']>([]);

  console.log(options)

  useDebouncedEffect(() => {
    if (searchTerm.length > 2) {
      searchRequest({
        variables: {
          filterInput: {
            searchTerm,
          },
          pageInput: {
            page: 0,
            size: 2000
          },
          sort: {
            field: Field.FirstName,
            direction: SortDirection.Asc
          } as ClientViewSortInput
        }
      });
    } else {
      setOptions([])
    }
  }, 300, [searchTerm])

  return <div className={'mt-15'}>
    <AscendAutocomplete fullWidth
                        options={options}
                        value={searchTerm}
                        onInputChange={(event, newInputValue) => {
                          setSearchTerm(newInputValue);
                        }}
                        filterOptions={(options) => options}
                        renderOption={(option, client: any) => {
                          console.log(option, client)
                          return <ClientGroupLine key={client.id}
                                                  onClick={() => {
                                                    setClients(prev => [...prev, {
                                                      clientId: client.id,
                                                      firstName: client.firstName,
                                                      lastName: client.lastName,
                                                      email: client.email
                                                    }]);
                                                  }}
                                                  firstName={client?.firstName}
                                                  lastName={client?.lastName}
                                                  email={client?.email} />
                        }}
                        renderInput={params => <TextInput placeholder={'Search client'}
                                                          {...params}
                                                          InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment:
                                                              <InputAdornment position="end" style={{position: 'absolute', right: '13px'}}>
                                                                {loading ? <CircularProgress className={'w-20 h-20'}/> : !!searchTerm && <CloseIcon cursor={'pointer'} onClick={() => {
                                                                  setSearchTerm('');
                                                                }} fontSize={'small'} color={'inherit'} />}
                                                              </InputAdornment>,
                                                          }} />} />
    <List>
      {clients.map(client => (
        <ClientGroupLine key={client?.clientId}
                         error={props.error?.extensions?.clientId === client?.clientId}
                         firstName={client?.firstName}
                         lastName={client?.lastName}
                         email={client?.email}
                         onIconClick={() => setClients(clients.filter(c => c.clientId != client.clientId))}/>
      ))}
    </List>
    <Typography style={{color: 'red'}}>{props.error?.message}</Typography>
    <Grid container className={'mt-10'} alignItems={"center"} justifyContent={"space-between"} direction={'row'}>
      <Grid item>
        <Button variant={'outlined'} onClick={props.onCancel}>CANCEL</Button>
      </Grid>
      <Grid item>
        <Button variant={'contained'} color={'primary'} onClick={() => props.onSubmit(clients)}>SAVE</Button>
      </Grid>
    </Grid>
  </div>
};
