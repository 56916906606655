import {gql, useQuery} from "@apollo/client";
import {client} from "../../../../../../../Apollo/ApolloClient";
import {DoctorViewOutput} from "../../../../../../../enrollment-types";

export function useDoctors(zip: string, searchTerm: string) {
  const {data, loading} = useQuery<{findProvidersByFilter: DoctorViewOutput[]}, {zip: string, searchTerm: string}>(
    gql(doctorsRequest), {
      client,
      skip: !searchTerm || !zip,
      variables: { zip, searchTerm },
    });

  return {loading, data: data?.findProvidersByFilter || []};
}


const doctorsRequest = `
query ($zip: String!, $searchTerm: String!) {
  findProvidersByFilter(filterInput: {zipCode: $zip, searchTerm: $searchTerm}) {
    addresses {
      addressLine1
      addressLine2
      id
      specialty
    }
    addressesNumber
    name
    npi
    pcpDetails {
      id
      networkDetails {
        carrierName
        name
      }
      pcpId
    }
  }
}
`;