// @flow
import * as React from 'react';
import {Checkbox, Divider, Stack, Typography} from "@mui/material";
import {usePlansCountByZipQuery} from "../../../types";
import {client} from "../../../Apollo/ApolloClient";
import Container from "@mui/material/Container";

type Props = {
  zip?: string | null
};

export function ClientDisclamer({zip}: Props) {
  const {maPlansCount, maOrgCount, loading} = usePlansCount(zip)

  if (loading) {
    return null;
  }

  return (
    <Container maxWidth="lg" sx={{pb: 10}}>
      <Stack spacing={2}>
        <Divider />
        <Stack direction={'row'} spacing={2} alignItems={'center'}>
          <Checkbox disabled />
          <Typography>
            <b>Recorded Line agreement</b>
          </Typography>
        </Stack>
        <Stack direction={'row'} spacing={2} alignItems={'flex-start'}>
          <Checkbox disabled />
          <Typography>
            <b>TPMO Agreement:</b><br/>
            We do not offer every plan available in your area. Currently we represent {maOrgCount} organizations which offer {maPlansCount} products
            in your area. Please contact http://medicare.gov/ , 1–800–MEDICARE, or your local State Health Insurance Program
            (SHIP) to get information on all of your options.
          </Typography>
        </Stack>
        <Stack direction={'row'} spacing={2} alignItems={'flex-start'}>
          <Checkbox disabled />
          <Typography>
            <b>SOA Agreement:</b><br/>
            I work for [CoverRight Insurance Services Inc.], and in your
            area, we have a wide variety of plans such as [Medicare
            Advantage plans, Medicare Advantage Prescription Drug
            plans, Stand-alone Prescription Drug plans, Medicare
            Supplements Insurance Plans, Optional Supplemental
            Benefits (OSBs), Stand-Alone Vision, Stand-Alone Dental]?.
            (Agent to list out all product types available). ?Would you
            like to discuss all of these options or are you only interested
            in certain ones?? Must wait for an affirmative response and
            proceed accordingly based on beneficiary's choice.<br/><br/>

            I can give you a brief overview of each of these plans, then you can decide which plan might be best for you
            based on your needs. Would that be ok? (Agent to wait for response).
            <br/><br/>

            This conversation has no effect on your current or future health overage unless you enroll in a plan today.
            Talking to me does not obligate you to enroll or automatically enroll you in a plan. (An affirmative
            response is required).
          </Typography>
        </Stack>
      </Stack>
    </Container>
  );
}

function usePlansCount(zip?: string | null) {
  const {loading, data} = usePlansCountByZipQuery({
    client: client,
    variables: {zip: zip!},
    skip: !zip
  })


  const maPlansPercent = data ? Math.floor(data.plansCountByZip.maPlansCount * 100 / data.plansCountByZip.maPlansTotalCount) : null;

  const maPlansCount = data ? data!.plansCountByZip.maPlansCount : null;

  const maOrgCount = data ? data!.plansCountByZip.maPlansParentOrgCount : null;

  const pdpPlansCount = data ? data!.plansCountByZip.pdpPlansCount: null;

  const pdpOrgCount = data ? data!.plansCountByZip.pdpPlansParentOrgCount: null;

  return { maPlansPercent, maPlansCount, maOrgCount, pdpPlansCount, pdpOrgCount, loading };
}
