import {DoctorAddressViewOutput, DoctorViewOutput} from "../../../../../../../enrollment-types";
import React from "react";
import {Box, Divider, IconButton, Popover, Typography} from "@mui/material";

type DoctorLineProps = {
  doctor: DoctorViewOutput,
  showDelete?: boolean,
  showPCP?: boolean,
  onDeleteClick?: () => void,
  onEditClick?: () => void,
}

export const DoctorLine = (props: DoctorLineProps) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  return <Box sx={{
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: {xs: 'column', md: 'row'},
  }}>
    <Box flex={'1 1 50%'} overflow={'hidden'}>
      <Typography variant={'body1'} color={'textPrimary'}>{props.doctor.name}</Typography>
      {props.showPCP && <Typography variant={'body1'} color={'textSecondary'}>PCP: {props.doctor.pcpDetails ? props.doctor.pcpDetails[0]?.pcpId : '-'}</Typography>}
    </Box>
    <Box flex={'1 1 50%'} overflow={'hidden'} className={'ml-8'}>
      <Typography noWrap align={'right'} color={'textSecondary'}>{props.doctor?.addresses[0]?.specialty}</Typography>
      <AddressBlock address={props.doctor.addresses[0]} />
      {props.doctor.addresses.length > 1 && <>
          <Typography onMouseEnter={(event: any) => {
            setAnchorEl(event.currentTarget);
          }}
                      onMouseLeave={() => {
                        setAnchorEl(null);
                      }}
                      align={'right'}
                      className={'fs-12 lh-15 mb-4'}>+ {props.doctor.addresses.length - 1} more</Typography>
          <Popover
              style={{pointerEvents: 'none'}}
              PaperProps={{
                className: 'ph-16 pv-8'
              }}
              open={open}
              anchorEl={anchorEl}
              elevation={2}
              onClose={() => {
                setAnchorEl(null);
              }}
              disableRestoreFocus
              hideBackdrop
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
          >
            {props.doctor.addresses.slice(1).map((a, i) => <div key={i}>
              <Divider className={'mb-4' + (i > 0 ? ' mt-8' : '')}/>
              <AddressBlock address={a} />
            </div>)}
          </Popover>
      </>}
    </Box>
    {props.showDelete && <Box display={'flex'} alignItems={'center'}>
        <IconButton size={'small'} className={'ml-16'} onClick={props.onEditClick}><img src={'/img/mode.svg'}/></IconButton>
        <IconButton size={'small'} className={'ml-8 mr-35'} onClick={props.onDeleteClick}><img src={'/img/delete-icon.svg'}/></IconButton>
    </Box>}
  </Box>
}

const AddressBlock = ({address}: {address?: DoctorAddressViewOutput}) => (
  <Box display={'flex'} flexDirection={'column'} marginTop={0} alignItems={{xs: 'flex-start', sm: 'flex-end'}}>
    <Typography variant={'body2'}>{address?.addressLine1}</Typography>
    <Typography variant={'body2'}>{address?.addressLine2}</Typography>
  </Box>
)
