// @flow
import * as React from 'react';
import {Check, Close} from "@mui/icons-material";
import {
  AgentCallAgendaTaskAssignmentTypeOutput,
  AgentCallAgendaTaskHistoryItemOutput,
  AgentCallAgendaTaskOutput
} from "../../../enrollment-types";
import DataGrid, {DataGridColumn} from "../../../shared/DataGrid";
import moment from "moment";
import {Link} from "react-router-dom";
import paths from "../../../router-paths";
import {ButtonBase, Typography} from "@mui/material";
import {useRoundRobinTaskHistoryDrawer, withRoundRobinTaskHistoryDrawer} from "./RoundRobinTaskHistoryDrawer";

type Props = {
  rows: AgentCallAgendaTaskOutput[],
  loading: boolean,
};

export function RoundRobinTasksF(props: Props) {
  const {loading, rows} = props;
  const {open: openHistory} = useRoundRobinTaskHistoryDrawer();

  return (
    <DataGrid columns={columns(openHistory)}
              page={0}
              hidePaginator
              rowsPerPage={1000}
              loading={loading}
              rows={rows}/>
  );
}

export const RoundRobinTasks = withRoundRobinTaskHistoryDrawer(RoundRobinTasksF);

const columns: (openHistory: (rows: AgentCallAgendaTaskHistoryItemOutput[]) => void) => DataGridColumn<AgentCallAgendaTaskOutput, keyof AgentCallAgendaTaskOutput>[] = (openHistory) => [
  {
    title: 'Created At',
    value: v => <Typography>{moment(v.createdAt).format('MM/DD/YY HH:mm:ss')}</Typography>,
  },
  {
    title: 'IsNew',
    value: v => v.isNew ? <Check color={'success'} /> : <Close color={'error'} />
  },
  {
    title: 'Completed',
    value: v => v.completed ? <Check color={'success'} /> : <Close color={'error'} />
  },
  {
    title: 'Client ',
    value: v => (
      <Typography component={Link}
                  color={'textPrimary'}
                  sx={{textDecoration: 'underline'}}
                  to={paths.client + '/' + v.clientId}>
        {v.clientName} ({v.clientEmail})
      </Typography>
    )
  },
  {
    title: 'Assigned to ',
    value: v =>  (
      v.currentAssignment.type === AgentCallAgendaTaskAssignmentTypeOutput.Agent ?
      <Typography>{v.currentAssignment.assignedAgentEmail} <Typography variant={'body2'}
                                                                       component="span"
                                                                       color={'text.secondary'}>
          (until {moment(v.currentAssignment.assignedUntil).format('MM/DD/YY HH:mm:ss')})
        </Typography>
      </Typography>:
      <Typography>CSR</Typography>
    )
  },
  {
    title: '',
    value: v =>  (
      <ButtonBase component={Typography}
                  onClick={() => openHistory(v.assignmentHistory)}
                  sx={{textDecoration: 'underline'}}>
        Show history
      </ButtonBase>
    )
  },

]