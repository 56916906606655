import React from "react";
import {
  CircularProgress,
  IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText,
  Typography
} from "@mui/material";
import * as _ from "lodash";
import AscendAutocomplete from "../AscendAutocomplete";
import TextInput from "../TextInput";
import {ScrollbarList} from "../ScrollbarList";
import {
  CalcDrugFrequencyPeriodViewOutput,
  FrequencyPeriodViewOutput, PreferredDrugViewOutput
} from "../../enrollment-types";
import {CalcDrugFrequencyPeriod, DrugInfoWithPack, DrugOutput, FrequencyPeriod, PackInfoOuput} from "../../types";
import {useDrugsWithPackage} from "../../Client/hooks/client-details-hooks/use-drugs-with-package";
import {useDrugPackagesInfoRequest} from "./hooks/use-drug-packages-info-request";
import {useGetPackagesRequest} from "./hooks/use-get-packages-request";
import {useDebouncedEffect} from "../hooks/useDebouncedEffect";
import {DrugLine} from "./DrugLine";
import {useDrugs} from "./hooks/use-drugs";
import {DrugDosage, DrugDosageModal} from "./modals/DrugDosageModal";
import ConfirmBrandedDrugModal from "./modals/ConfirmBrandedDrugModal";
import NoPackagesModal from "./modals/NoPackagesModal";
import {makeStyles, createStyles} from "@mui/styles";

const useStyles = makeStyles({
  link: {
    cursor: 'pointer',
    textDecoration: 'underline',
    width: 90
  },
  title: {
    fontWeight: 500,
  },
  loadingIcon: {
    width: '20px!important',
    height: '20px!important',
  },
  lineWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '13px 16px 8px 20px',
    borderBottom: '1px solid rgba(28, 67, 79, 0.12)',
    marginBottom: 8,
    background: 'white',
    border: '1px solid #CCD8DC',
    borderRadius: 4,
  },
});

interface DrugSelectionProps {
  value: PreferredDrugViewOutput[],
  onChange: (data: PreferredDrugViewOutput[]) => void,
}

export const DrugSelection = (props: DrugSelectionProps) => {
  const [selectedValue, setSelectedValue] = React.useState<DrugInfoWithPack>();
  const [selectedDrug, setSelectedDrug] = React.useState<DrugName>();
  const [selectedDosage, setSelectedDosage] = React.useState<PreferredDrug>();
  const [showAutocomplete, setShowAutocomplete] = React.useState(false);
  const [brandedDrugModalOpen, setBrandedDrugModalOpen] = React.useState(false);
  const [drugDosageModalOpen, setDrugDosageModalOpen] = React.useState(false);
  const [noPackagesModalOpen, setNoPackagesModalOpen] = React.useState(false);
  const [packages, setPackages] = React.useState<PackInfoOuput[]>();
  const inputRef = React.useRef(null);

  const {refetch: getDrugs} = useDrugsWithPackage();

  const [drugLines, setDrugLines] = React.useState<Array<DrugOutput & {notSupported: boolean}>>([]);

  const [getPackages] = useGetPackagesRequest({
    onCompleted: data => {
      if (data?.packages.length) {
        setPackages(data.packages);
        setDrugDosageModalOpen(true);
      } else {
        console.error('There is no drugs with this name')
      }
    }
  });

  React.useEffect(() => {
    if (props.value.length) {
      getDrugs({ pairs: props.value.map(d => ({ packageRxcui: d.dosageRxcui, productRxcui: d.productRxcui })) })
        .then(res => {
          if (res.data.drugsWithPackage) {
            setDrugLines(res.data.drugsWithPackage.map(info => {
              const drug = props.value.find(d => d.productRxcui === info.rxcui && d.dosageRxcui === info.packRxcui) as PreferredDrugViewOutput;
              return {
                quantity: drug.quantity,
                frequency: drug.frequency as unknown as CalcDrugFrequencyPeriod,
                genericName: info.genericName,
                genericRxcui: info.genericRxcui,
                isGeneric: !!info.isGeneric,
                name: info.name,
                packName: info.packName,
                packRxcui: info.packRxcui || '',
                purchaseFrequency: drug.purchaseFrequency as unknown as FrequencyPeriod,
                rxcui: info.rxcui || '',
                notSupported: !!info.notSupported
              }
            }))
          }
        })
        .catch(() => setDrugLines([]))
    } else {
      setDrugLines([])
    }
  }, [props.value]);

  React.useEffect(() => {
    if (inputRef.current && showAutocomplete) {
      (inputRef.current as any).focus();
    }
  }, [showAutocomplete, inputRef.current])

  React.useEffect(() => {
    if (selectedValue) {
      getDrugInfo({variables: {rxcui: selectedValue.rxcui as string}})
    }
  }, [selectedValue])

  React.useEffect(() => {
    if (selectedDrug) {
      getPackages({
        variables: {
          rxcui: selectedDrug.rxcui as string
        }
      })
    }
  }, [selectedDrug])

  const onBrandedDrugModalClose = (useGeneric?: boolean) => {
    setBrandedDrugModalOpen(false);
    if (typeof useGeneric !== 'undefined') {
      if (selectedValue) {
        setSelectedDrug(useGeneric ?
          {name: selectedValue.genericName, rxcui: selectedValue.genericRxcui} :
          {name: selectedValue.name, rxcui: selectedValue.rxcui}
        )
      }
    } else {
      setSelectedValue(undefined);
    }
  }

  const onDrugDosageModalClose = async (result?: DrugDosage | boolean) => {
    setDrugDosageModalOpen(false);
    setSelectedDosage(undefined);

    if (typeof result === 'undefined') {
      setSelectedValue(undefined);
    }

    if (result === false) {
      if ((!selectedValue?.isGeneric && !packagesInfoData?.drugPackagesInfo?.hasBrandedPackages) ||
        (selectedValue?.isGeneric && !packagesInfoData?.drugPackagesInfo?.hasGenericPackages)) {
        setNoPackagesModalOpen(true);
      } else {
        if (!selectedValue?.isGeneric) {
          setBrandedDrugModalOpen(true);
          return false;
        }
      }
    }

    if (typeof result !== 'boolean' && !!result?.dosage && selectedDrug) {
      const {data} = await getDrugs( {pairs: [{packageRxcui: result.dosage, productRxcui: selectedDrug.rxcui as string}]})
      let value: any;
      const prevDrug = props.value.find(drug => drug.dosageRxcui === result.dosage);
      if (prevDrug) {
        value = [...props.value.reduce((prev, cur) => {
          if (cur.dosageRxcui === result.dosage) {
            return [...prev, {
              frequency: result.frequency as CalcDrugFrequencyPeriodViewOutput,
              quantity: result.quantity as number,
              purchaseFrequency: result.purchaseFrequency as unknown as FrequencyPeriodViewOutput,
              dosageRxcui: data.drugsWithPackage[0].packRxcui as string,
              productRxcui: data.drugsWithPackage[0].rxcui as string,
            }];
          }
          return [...prev, cur];
        }, [] as PreferredDrugViewOutput[])]
      } else if (selectedDosage) {
        value = [...props.value.reduce((prev, cur) => {
          if (cur.dosageRxcui === selectedDosage.dosageRxcui) {
            return [...prev, {
              frequency: result.frequency as CalcDrugFrequencyPeriodViewOutput,
              quantity: result.quantity as number,
              purchaseFrequency: result.purchaseFrequency as unknown as FrequencyPeriodViewOutput,
              dosageRxcui: data.drugsWithPackage[0].packRxcui as string,
              productRxcui: data.drugsWithPackage[0].rxcui as string,
            }];
          }
          return [...prev, cur];
        }, [] as PreferredDrugViewOutput[])]
      } else {
        value = [...props.value, {
          frequency: result.frequency as CalcDrugFrequencyPeriodViewOutput,
          quantity: result.quantity as number,
          purchaseFrequency: result.purchaseFrequency as unknown as FrequencyPeriodViewOutput,
          dosageRxcui: data.drugsWithPackage[0].packRxcui as string,
          productRxcui: data.drugsWithPackage[0].rxcui as string,
        }]
      }
      props.onChange(value);
      setSelectedValue(undefined);
      setShowAutocomplete(false);
    }

    return false;
  }

  const onNoPackagesModalClose = (switchTo?: {name: string, rxcui: string}) => {
    if (switchTo) {
      setSelectedDrug(switchTo);
    } else {
      setSelectedDosage(undefined);
      setSelectedValue(undefined);
    }
    setNoPackagesModalOpen(false);
  }

  const [getDrugInfo, {data: packagesInfoData}] = useDrugPackagesInfoRequest({
    onCompleted: data => {
      if (selectedValue) {
        if ((!selectedValue.isGeneric && !data.drugPackagesInfo.hasBrandedPackages) ||
          (selectedValue.isGeneric && !data.drugPackagesInfo.hasGenericPackages)) {
          setNoPackagesModalOpen(true);
        } else {
          if (!selectedValue.isGeneric && selectedValue.genericName && data.drugPackagesInfo.hasGenericPackages) {
            setBrandedDrugModalOpen(true);
          } else {
            setSelectedDrug({name: selectedValue.name, rxcui: selectedValue.rxcui})
            getPackages({
              variables: {
                rxcui: selectedValue.rxcui as string
              }
            })
          }
        }
      }
    }
  });



  return <>
    <NoPackagesModal info={packagesInfoData?.drugPackagesInfo} open={!!noPackagesModalOpen} onClose={onNoPackagesModalClose}/>
    <ConfirmBrandedDrugModal drug={selectedValue} open={!!brandedDrugModalOpen} onClose={onBrandedDrugModalClose}/>
    <DrugDosageModal packages={packages} drug={selectedDrug} dosage={selectedDosage} open={!!drugDosageModalOpen} onClose={onDrugDosageModalClose}/>
    <List>
      {drugLines.map((item, index) =>
        <DrugRow key={item.packRxcui} drug={item} n={index + 1} onDeleteClick={() => {
          const result = props.value.filter(v => v.productRxcui !== item.rxcui || v.dosageRxcui !== item.packRxcui);
          props.onChange(result);
        }} onEditClick={() => {
          setSelectedDrug({rxcui: item.rxcui || '', name: item.name || ''});
          setSelectedDosage({
            dosage: item.packName || '',
            dosageRxcui: item.packRxcui || '',
            frequency: item.frequency as unknown as CalcDrugFrequencyPeriodViewOutput || '',
            ndc: item.frequency || '',
            productId: item.name || '',
            productRxcui: item.rxcui || '',
            quantity: item.quantity || 0,
            purchaseFrequency: item.purchaseFrequency as unknown as FrequencyPeriodViewOutput || FrequencyPeriodViewOutput.Monthly
          });
          setDrugDosageModalOpen(true);
        }} />)}
    </List>
    {!!props.value.length && <div className={'h-16'} />}
    {showAutocomplete && <Autocomplete onSelect={setSelectedValue} onBlur={() => setShowAutocomplete(false)} />}
    {!showAutocomplete && <Typography className={'dark-green pointer mt-10'}
                                      variant={'body2'}
                                      onClick={() => setShowAutocomplete(true)}>
      <img src={'/img/add-icon.svg'}/> Add Drug
    </Typography>}
  </>
}

const Autocomplete = (props: {onSelect: (drug: DrugInfoWithPack) => void, onBlur: () => void}) => {
  const classes = useStyles();
  const [autocompleteValue, setAutocompleteValue] = React.useState<string>('');
  const [open, setOpen] = React.useState(true);
  const [getPrescriptions, data] = useDrugs();
  const inputRef = React.useRef(null);

  useDebouncedEffect(() => {
    if (autocompleteValue) {
      getPrescriptions({
        variables: {
          name: autocompleteValue
        }
      })
    }
  }, 300, [autocompleteValue])

  const autocompleteOpen = React.useMemo(() => {
    return !!autocompleteValue && !data.loading && data.data?.drugs && open
  }, [autocompleteValue, data, open])

  return <AscendAutocomplete fullWidth
                             open={autocompleteOpen}
                             onClose={() => {
                               setTimeout(() => setOpen(false), 300) // todo: hack - fix closing autocomplete by blur
                             }}
                             onFocus={() => setOpen(true)}
                             options={data.data?.drugs || []}
                             value={autocompleteValue}
                             filterOptions={(options) => options}
                             onBlur={() => {
                               if (!autocompleteValue) {
                                 props.onBlur();
                               }
                             }}
                             onInputChange={(event, newInputValue) => {
                               setAutocompleteValue(newInputValue);
                             }}
                             clearOnBlur
                             popupIcon={data.loading ? <CircularProgress className={classes.loadingIcon}/> : undefined}
                             renderOption={(option, drug: any) => <DrugLine
                               sx={{p: 1}}
                               onClick={() => props.onSelect(drug)}
                               drug={drug} />}
                             ListboxComponent={ScrollbarList as any}
                             renderInput={params => <TextInput inputRef={inputRef}
                                                               placeholder={'Search drug name'}
                                                               {...params} />} />

}


export type DrugName = {
  name?: string | null
  rxcui?: string | null
}

interface PreferredDrug {
  ndc?: string;
  productId?: string;
  quantity: number;
  frequency: CalcDrugFrequencyPeriodViewOutput;
  dosage: string;
  productRxcui: string;
  dosageRxcui: string;
  purchaseFrequency: FrequencyPeriodViewOutput,
}

interface DrugRowProps {
  drug: DrugOutput & {notSupported: boolean},
  n?: number,
  onEditClick: () => void,
  onDeleteClick: () => void,
}

const useRowStyles = makeStyles(() =>
  createStyles({
    listItem: {
      alignItems: 'flex-start'
    },
    avatar: {
      minWidth: 20,
      marginTop: 4
    }
  }),
);

export const DrugRow = (props: DrugRowProps) => {
  const classes = useRowStyles();

  const title = props?.drug?.packName ? props?.drug?.packName + (props.drug.notSupported ? ' (not supported)' : '') : '';

  let subtitle = props?.drug?.packRxcui ? ` rxcui: ${props?.drug?.packRxcui}` : '';

  subtitle += ', Quantity: ' + props.drug?.quantity + ' ' + _.lowerCase(props.drug?.frequency);

  return <ListItem dense disableGutters className={classes.listItem} sx={{opacity: props.drug.notSupported ? .3 : 1}}>
    <ListItemAvatar className={classes.avatar}>
      <div className={'mr-5'}><Typography>{props.n}.</Typography></div>
    </ListItemAvatar>
    <ListItemText
      primary={title}
      secondary={
        <Typography color={'textSecondary'}>{subtitle}</Typography>
      }
    />
    <ListItemSecondaryAction>
      {!props.drug.notSupported && <IconButton size={'small'} className={'ml-16'} onClick={props.onEditClick}>
        <img src={'/img/mode.svg'}/>
      </IconButton>}
      <IconButton size={'small'} className={'ml-8'} onClick={props.onDeleteClick}><img src={'/img/delete-icon.svg'}/></IconButton>
    </ListItemSecondaryAction>
  </ListItem>
};
