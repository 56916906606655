import {config} from "../config/config";
import {keycloak} from "../shared/keycloak";
import {errorTracker, graphQLErrorHandler} from "../GraphQLErrorHandler";

import { split, HttpLink, ApolloClient, InMemoryCache, from } from '@apollo/client';
import { setContext } from "@apollo/client/link/context";
import { onError, ErrorResponse } from "@apollo/client/link/error";

const httpLink = new HttpLink({
  uri: config.graphQL2022,
  headers: {
    "content-type": "application/json",
  }
});


export const getToken = () => {
  return new Promise((resolve, reject) => {
    keycloak.updateToken(5)
      .then(() => {
        resolve(keycloak.token);
      })
      .catch(() => {
        reject();
      });
  });
}

export const authLink = setContext(async (_, { headers }: any) => {
  const token = await getToken().catch(e => {
    throw new Error('Update token failed');
  });
  return {
    headers: {
      authorization: token ? `Bearer ${token}` : "",
      ...headers,
    }
  };
});

export const elink = onError((errResponse: ErrorResponse) => {
  try {
    getToken().then(token => errorTracker(errResponse, token as any)).catch(() => errorTracker(errResponse));
  } catch (e) {
    errorTracker(errResponse);
  }
  if (graphQLErrorHandler) {
    graphQLErrorHandler(errResponse);
  }
});

const link =
  from([
    authLink,
    elink,
    httpLink,
  ])
;

export const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache({
    addTypename: false
  })
});

export const unathorizedClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache({
    addTypename: false
  })
});
