import React, {useEffect} from "react";
import {Box, Grid, GridSize, Typography} from "@mui/material";
import FollowUps from "./components/FollowUps";
import moment from "moment";
import {AgentPerformanceMetricOutput} from "../enrollment-types";
import momentDurationFormatSetup from "moment-duration-format";
import Contacted from "./components/Contacted";
import SalesQualified from "./components/SalesQualified";

import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import useAgentDashboardCounters from "./hooks/useAgentDashboardCounters";
import Goals from "./components/Goals";

momentDurationFormatSetup(moment as any);

const AgentDashboard = (props: {data: AgentPerformanceMetricOutput[], agentId?: string}) => {
  const [value, setValue] = React.useState('1');
  const {counters, refetch} = useAgentDashboardCounters(props.agentId);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return <Box sx={{ width: '100%', typography: 'body1' }}>
    <TabContext value={value}>
      <TabList onChange={handleChange} variant={'fullWidth'}>
        <Tab label={`Todays SQLs${toBrackets(counters.todaySQL)}`} value="1" />
        <Tab label={`Future SQLs${toBrackets(counters.futureSQL)}`} value="2" />
        <Tab label={`Todays Follow-Ups${toBrackets(counters.todayFollowUps)}`} value="3" />
        <Tab label={`Future Follow-Ups${toBrackets(counters.futureFollowUps)}`} value="4" />
        <Tab label={`Missed Appt.${toBrackets(counters.missingApps)}`} value="5" />
        <Tab label={`Contacted${toBrackets(counters.contacted)}`} value="6" />
        <Tab label={`Goals & Metrics`} value="7" />
      </TabList>
      <Box sx={{ borderBottom: 8, borderColor: 'divider', mt: -1 }} />

      <TabPanel value="1"><SalesQualified onRefetch={refetch} agentId={props.agentId} /></TabPanel>
      <TabPanel value="2"><SalesQualified onRefetch={refetch} agentId={props.agentId} future /></TabPanel>
      <TabPanel value="3"><FollowUps onRefetch={refetch} agentId={props.agentId} future={false}/></TabPanel>
      <TabPanel value="4"><FollowUps onRefetch={refetch} agentId={props.agentId} future /></TabPanel>
      <TabPanel value="5"><FollowUps onRefetch={refetch} agentId={props.agentId} missingApps /></TabPanel>
      <TabPanel value="6"><Contacted onRefetch={refetch} agentId={props.agentId} /></TabPanel>
      <TabPanel value="7"><Goals {...props} /></TabPanel>
    </TabContext>
  </Box>
}

export default AgentDashboard;

const toBrackets = (value?: number) => typeof value === 'number' ? ` (${value})` : '';
